import produce from "immer";
import { CustomerVendorAttachmentActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  CLEAR_CUSTOMER_VENDOR_ATTACHMENT,
  CLEAR_CUSTOMER_VENDOR_ATTACHMENT_STATE,
  FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_FAILED,
  FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_PROGRESS,
  FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_SUCCESS,
} from "./customerVendorAttachmentActions";
import {
  defaultCustomerVendorAttachment,
  defaultCustomerVendorAttachmentState,
} from "./defaultState";

export const customerVendorAttachmentReducer = (
  state: IStoreState["customerVendorAttachment"] = defaultCustomerVendorAttachmentState,
  action: CustomerVendorAttachmentActions,
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }

    case CLEAR_CUSTOMER_VENDOR_ATTACHMENT: {
      const newState = produce(state, (draftState) => {
        draftState.attachemnt.loading = LoadState.NotLoaded;
        draftState.attachemnt.data = defaultCustomerVendorAttachment;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_VENDOR_ATTACHMENT_STATE: {
      return defaultCustomerVendorAttachmentState;
    }
    default: {
      return state;
    }
  }
};
