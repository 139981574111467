/* eslint-disable react-hooks/exhaustive-deps */
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";
import { IDepartment } from "../../redux/department";
import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { defaultDepartment } from "../../redux/department/defaultState";

const INITIAL_STATE: IDepartment = defaultDepartment;

interface IAutoSearchProps {
  label: string;
  value: { department_name: string; department_uuid: string };
  onSelect: (data: IDepartment) => void;
  disabled?: boolean;
  error?: string;
}

export const DepartmentAutoSearch: React.FC<IAutoSearchProps> = (props) => {
  const { value, onSelect, label, disabled, error } = props;
  const [options, setOptions] = React.useState<readonly IDepartment[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearchText] = React.useState<any>("");
  const [openRightPanel, setOpenRightPanel] = React.useState(false);

  const fetchSuggestion = async (typedValue: string) => {
    setLoading(true);
    try {
      let queryParams = "";
      if (typedValue.length > 0) {
        queryParams = `?columns=department_name&value=${typedValue}&pageNo=1&itemPerPage=20`;
      }
      const res = await api.get(`/department/get-department${queryParams}`);
      const data: IDepartment[] = res.data.data;
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  const getOptionLabel = (option: any) => {
    if (typeof option === "string") {
      return option;
    }
    return option.department_name || "";
  };

  const getValue = () => {
    if (value && typeof value === "object") {
      return (
        options.find(
          (option) => option.department_uuid === value?.department_uuid,
        ) || null
      );
    }
    return options.find((option) => option.department_uuid === value) || null;
  };

  const handleToggle = () => {
    setOpenRightPanel(!openRightPanel);
  };

  const handleOnComplete = (customer: IDepartment) => {
    setOptions([customer, ...options]);
    setSearchText(customer.department_name);
    handleToggle();
  };

  const handleDropDownOnFocus = () => {
    if (search.length === 0 && options.length === 0) {
      debounceFn("");
    }
  };

  React.useEffect(() => {
    if (search && search !== value) {
      debounceFn(search);
    }
  }, [search]);

  React.useEffect(() => {
    if (
      value &&
      typeof value === "object" &&
      value?.department_uuid?.length > 0
    ) {
      const option: IDepartment = {
        ...INITIAL_STATE,
        department_uuid: value.department_uuid,
        department_name: value.department_name,
      };
      setOptions([option]);
    }
  }, [value]);

  return (
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
        </Box>
      )}
      <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
        <Autocomplete
          id="customer-auto-search"
          fullWidth
          freeSolo
          disabled={disabled}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingTop: "2px",
              paddingBottom: "2px",
              fontSize: "0.8rem",
              color: "rgb(38, 38, 38)",
              width: "100%",
              backgroundColor: disabled ? "#f8f9fb" : "inherit",
            },
          }}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) =>
            typeof option === "string"
              ? option === value //@ts-ignore
              : option.department_name === value.department_name
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          onFocus={handleDropDownOnFocus}
          value={getValue()}
          loading={loading}
          noOptionsText="No Categories"
          //@ts-ignore
          onChange={(
            event: React.ChangeEvent<HTMLElement>,
            newValue: IDepartment | null,
          ) => {
            if (newValue) {
              onSelect(newValue);
            } else {
              onSelect(INITIAL_STATE);
            }
          }}
          onInputChange={(event, newInputValue) => {
            if ((event && event.type === "change") || !newInputValue) {
              setSearchText(newInputValue);
            }
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              disabled={disabled}
              error={error ? true : false}
              helperText={error}
            />
          )}
        />
      </Stack>
    </>
  );
};
