import { LoadState } from "../../constants/enums";
import {
  ICustomerDispatchAddress,
  ICustomerDispatchAddressState,
} from "./customDispatchAddress.types";

export const initialCustomerDispatchAddress: ICustomerDispatchAddress = {
  customer_dispatch_address_uuid: "",
  customer_uuid: null,
  customer_name: "",
  dispatch_name: null,
  customer_dispatch_address_line1: null,
  customer_dispatch_address_line2: null,
  customer_dispatch_address_city: null,
  customer_dispatch_address_state: null,
  customer_dispatch_address_pincode: null,
  customer_dispatch_address_country: null,
  dispatch_through: null,
  transport_id: null,
  transport_doc_no: null,
  transport_doc_date: null,
  vehicle_no: null,
  status: "ACTIVE",
};
export const initialCustomerDispatchAddressState: ICustomerDispatchAddressState =
  {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
    customerDispatchAddress: initialCustomerDispatchAddress,
    customDispatchAddressLoading: LoadState.NotLoaded,
    customDispatchAddressError: null,
  };
