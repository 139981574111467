import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialCompanyState } from "./company.types";
import { CompanyActions } from ".";
import {
  CLEAR_COMPANY_STATE,
  FETCH_COMPANY_LIST_FAILED,
  FETCH_COMPANY_LIST_PROGRESS,
  FETCH_COMPANY_LIST_SUCCESS,
} from "./companyActions";

export const companyReducer = (
  state: IStoreState["company"] = initialCompanyState,
  action: CompanyActions,
) => {
  switch (action.type) {
    case FETCH_COMPANY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_COMPANY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_COMPANY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_COMPANY_STATE: {
      return initialCompanyState;
    }
    default: {
      return state;
    }
  }
};
