import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";

import { initialJobContactState } from "./job_contacts_types";
import { ContactsActions } from ".";
import {
  CLEAR_CONTACT,
  CLEAR_CONTACT_STATE,
  FETCH_CONTACTS_LIST_FAILED,
  FETCH_CONTACTS_LIST_PROGRESS,
  FETCH_CONTACTS_LIST_SUCCESS,
  FETCH_CONTACT_FAILED,
  FETCH_CONTACT_PROGRESS,
  FETCH_CONTACT_SUCCESS,
  FETCH_CUSTOMER_CONTACT_LIST_FAILED,
  FETCH_CUSTOMER_CONTACT_LIST_PROGRESS,
  FETCH_CUSTOMER_CONTACT_LIST_SUCCESS,
} from "./job_contactsActions";

export const job_contactsReducer = (
  state: IStoreState["job_contacts"] = initialJobContactState,
  action: ContactsActions,
) => {
  switch (action.type) {
    case FETCH_CONTACTS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CONTACTS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CONTACTS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_CONTACT_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_CONTACT_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_CONTACT_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_CONTACT_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.contactLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CONTACT_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.contactLoading = LoadState.Loaded;
        draftState.contact = data;
      });
      return newState;
    }
    case FETCH_CONTACT_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.contactLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_CONTACT: {
      const newState = produce(state, (draftState) => {
        draftState.contactLoading = LoadState.NotLoaded;
        draftState.contact = initialJobContactState["contact"];
      });
      return newState;
    }
    case CLEAR_CONTACT_STATE: {
      return initialJobContactState;
    }
    default: {
      return state;
    }
  }
};
