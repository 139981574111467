import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { InvoiceActions, defaultInvoiceState } from ".";
import {
  CLEAR_INVOICE,
  CLEAR_INVOICE_STATE,
  FETCH_INVOICE_FAILED,
  FETCH_INVOICE_LIST_FAILED,
  FETCH_INVOICE_LIST_PROGRESS,
  FETCH_INVOICE_LIST_SUCCESS,
  FETCH_INVOICE_PROGRESS,
  FETCH_INVOICE_SUCCESS,
} from "./invoiceActions";

export const invoiceReducer = (
  state: IStoreState["invoices"] = defaultInvoiceState,
  action: InvoiceActions,
) => {
  switch (action.type) {
    case FETCH_INVOICE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_INVOICE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = list;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_INVOICE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_INVOICE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.invoice.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_INVOICE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.invoice.loading = LoadState.Loaded;
        draftState.invoice.data = data;
      });
      return newState;
    }
    case FETCH_INVOICE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.invoice.loading = LoadState.Failed;
        draftState.invoice.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_INVOICE: {
      const newState = produce(state, (draftState) => {
        draftState.invoice.loading = LoadState.NotLoaded;
        draftState.invoice.data = defaultInvoiceState["invoice"].data;
        draftState.invoice.error = null;
      });
      return newState;
    }
    case CLEAR_INVOICE_STATE: {
      return defaultInvoiceState;
    }
    default: {
      return state;
    }
  }
};
