import { LoadState } from "../../constants/enums";
import {
  ICustomerDeliveryAddress,
  ICustomerDeliveryAddressState,
} from "./customDeliveryAddress.types";

export const initialCustomerDeliveryAddress: ICustomerDeliveryAddress = {
  customer_delivery_address_uuid: "",
  customer_uuid: null,
  customer_name: "",
  delivery_name: null,
  customer_delivery_address_line1: null,
  customer_delivery_address_line2: null,
  customer_delivery_address_city: null,
  customer_delivery_address_state: null,
  customer_delivery_address_pincode: null,
  customer_delivery_address_country: null,
  status: "ACTIVE",
};
export const initialCustomerDeliveryAddressState: ICustomerDeliveryAddressState =
  {
    list: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
    customerDeliveryAddress: initialCustomerDeliveryAddress,
    customDeliveryAddressLoading: LoadState.NotLoaded,
    customDeliveryAddressError: null,
  };
