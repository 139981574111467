import { LoadState } from "../../constants/enums";
import {
  ICustomerVendorAttachment,
  ICustomerVendorAttachmentState,
} from "./customerVendorAttachment.types";

export const defaultCustomerVendorAttachment: ICustomerVendorAttachment = {
  customer_attachment_uuid: null,
  customer_attachment_name: "",
  customer_uuid: "",
  customer_name: "",
  is_vendor: "NO",
  link: "",
  status: "ACTIVE",
};
export const defaultCustomerVendorAttachmentState: ICustomerVendorAttachmentState =
  {
    list: {
      data: [],
      totalRecords: 0,
      loading: LoadState.NotLoaded,
      error: null,
    },
    attachemnt: {
      data: defaultCustomerVendorAttachment,
      loading: LoadState.NotLoaded,
      error: null,
    },
  };
