import produce from "immer";
import { IStoreState } from "../../initialStoreState";
import {
  CLEAR_APPROVAL_QUOTE_STATE,
  FETCH_APPROVAL_QUOTE_LIST_PROGRESS,
  FETCH_APPROVAL_QUOTE_LIST_SUCCESS,
} from "./approvalQuoteActions";
import { defaultApprovalQuoteState } from "./defaultState";
import { LoadState } from "../../../constants/enums";
import { ApprovalQuoteActions } from ".";

export const quotesApprovalReducer = (
  state: IStoreState["approvalQuotes"] = defaultApprovalQuoteState,
  action: ApprovalQuoteActions,
) => {
  switch (action.type) {
    case FETCH_APPROVAL_QUOTE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_APPROVAL_QUOTE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }

    case CLEAR_APPROVAL_QUOTE_STATE: {
      return defaultApprovalQuoteState;
    }
    default: {
      return state;
    }
  }
};
