import produce from "immer";
import {
  CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE,
  CLEAR_CUSTOMER_DELIVERY_ADDRESS,
  CustomerDeliveryAddressActions,
  FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED,
  FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED,
  FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS,
  FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS,
  FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS,
  FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS,
  initialCustomerDeliveryAddress,
  initialCustomerDeliveryAddressState,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

export const customerDeliveryAddressReducer = (
  state: IStoreState["customerDeliveryAddress"] = initialCustomerDeliveryAddressState,
  action: CustomerDeliveryAddressActions,
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DELILVERY_ADDRESS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_CUSTOMER_DELILVERY_ADDRESS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customDeliveryAddressLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DELILVERY_ADDRESS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customDeliveryAddressLoading = LoadState.Loaded;
        draftState.customerDeliveryAddress = data;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DELILVERY_ADDRESS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customDeliveryAddressLoading = LoadState.Failed;
        draftState.customDeliveryAddressError = errorMessage;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_DELIVERY_ADDRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customDeliveryAddressLoading = LoadState.NotLoaded;
        draftState.customerDeliveryAddress = initialCustomerDeliveryAddress;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_DELILVERY_ADDRESS_STATE: {
      return initialCustomerDeliveryAddressState;
    }
    default: {
      return state;
    }
  }
};
