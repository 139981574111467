import { Box, Chip, CircularProgress, MenuItem, Select, SelectChangeEvent, Theme, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomFormLabel } from "../formsComponents";
import { api } from "../../api/api";
import { IBranch } from "../../redux/DataManagement/dataManagement.types";
import { IRockWorthBranchAutoSearchProps } from "./AutoCompleteSearches.types";

export default function RockworthBranchAutoSearch(props: IRockWorthBranchAutoSearchProps) {
  const { label, onSelect, value } = props;
  const [branchOptions, setBranchOptions] = useState<any>([]);
  const [selectedBranches, setSelectedBranches] = useState<any>(value.length > 1 ? value : []);
  const [loading, setLoading] = useState(true);
  const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
    const values = e.target.value as string[];

    // selectedBranches based on  branch names
    const selectedBranchesToAdd = branchOptions.filter((branch: IBranch) => values.includes(branch.branch_name));

    // Checking if the selected branches are already in selectedBranches
    const updatedSelectedBranches = selectedBranches.filter((branch: { branch_name: string, branch_uuid: string }) =>
      !selectedBranchesToAdd.some((selectedBranch: { branch_name: string, branch_uuid: string }) => selectedBranch.branch_uuid === branch.branch_uuid)
    );
    const finalSelectedBranches = updatedSelectedBranches.length === selectedBranches.length
      ? [...selectedBranches, ...selectedBranchesToAdd]
      : updatedSelectedBranches;

    setSelectedBranches(finalSelectedBranches);
  }
  useEffect(() => {
    onSelect(selectedBranches);
  }, [selectedBranches])
  const fetchBranchList = async () => {
    setLoading(true);
    try {
      const res = await api.get("/dataManagement/get-branch");
      const data = res.data.data;
      const validBranches = data.filter((res: IBranch) => res.branch_name.length !== 0)
      const sortedData = validBranches.sort((a: IBranch, b: IBranch) => a.branch_name.localeCompare(b.branch_name))
      setBranchOptions(sortedData);
    } catch (err) {
      console.error("Error fetching data:", err);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchBranchList();

  }, [])
  return (
    <Box>
      <CustomFormLabel>{label}</CustomFormLabel>
      <Select
        fullWidth
        multiple
        value={selectedBranches}
        name="branch_name"
        onChange={handleSelectChange}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", gap: 0.5 }}>
            {
              selected.map((value: IBranch) => (
                <Chip key={value.branch_uuid} label={value.branch_name} />
              ))
            }
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {
          loading && (
            <MenuItem disabled><CircularProgress color="inherit" size={20} /></MenuItem>
          )
        }
        {
          !loading && branchOptions.map((singleBranchData: IBranch, index: number) => {
            return (
              <MenuItem
                key={index}
                value={singleBranchData.branch_name}
                sx={{
                  backgroundColor: selectedBranches.some((branch: { branch_name: string }) => branch.branch_name === singleBranchData.branch_name) ? '#FEF3F0' : 'inherit',
                }}
              >
                {singleBranchData.branch_name}
              </MenuItem>
            )
          })
        }
      </Select>
    </Box>
  );
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

