import { LoadState } from "../../constants/enums";
import { IBillingCompany, IBillingCompanyState } from "./billingCompany.types";

export const defaultBillingCompany: IBillingCompany = {
  billing_company_uuid: "",
  billing_company_name: "",
  billing_company_line1: null,
  billing_company_line2: null,
  billing_company_city: null,
  billing_company_state: null,
  billing_company_pincode: null,
  billing_company_country: null,
  mobile: "",
  phone_number: "",
  website: "",
  gst_in: "",
  mail_id: "",
  bank_name: "",
  branch: "",
  account_no: "",
  swift_code: "",
  ifsc_code: "",
};
export const defaultBillingCompanyState: IBillingCompanyState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  billingCompany: {
    data: defaultBillingCompany,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
