import { LoadState } from "../../constants/enums";
import { IVendor, IVendorState } from "./vendor.typs";

export const initialVendor: IVendor = {
  vendor_uuid: "",
  vendor_name: "",
  registration_type: null,
  vendor_address_line1: null,
  vendor_address_line2: null,
  vendor_address_city: null,
  vendor_address_state: null,
  vendor_address_pincode: null,
  vendor_address_country: null,
  mobile: null,
  phone_number: null,
  website: null,
  gst_in: null,
  mail_id: null,
  bank_name: null,
  bank_account_no: null,
  bank_ifsc_code: null,
  bank_branch_name: null,
  note: null,
  pan_no: null,
};
export const initialVendorState: IVendorState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  vendor: {
    data: initialVendor,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
