import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { initialBankDetailsState } from "./defaultState";
import { BankDetailsActions } from ".";
import {
  CLEAR_BANK_DETAILS,
  CLEAR_BANK_DETAILS_STATE,
  FETCH_BANK_DETAILS_LIST_FAILED,
  FETCH_BANK_DETAILS_LIST_PROGRESS,
  FETCH_BANK_DETAILS_LIST_SUCCESS,
  FETCH_CUSTOMER_BANK_DETAILS_LIST_FAILED,
  FETCH_CUSTOMER_BANK_DETAILS_LIST_PROGRESS,
  FETCH_CUSTOMER_BANK_DETAILS_LIST_SUCCESS,
} from "./bankDetailsActions";

export const bankDetailsReducer = (
  state: IStoreState["bankDetails"] = initialBankDetailsState,
  action: BankDetailsActions,
) => {
  switch (action.type) {
    case FETCH_BANK_DETAILS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_BANK_DETAILS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_BANK_DETAILS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BANK_DETAILS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BANK_DETAILS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BANK_DETAILS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case CLEAR_BANK_DETAILS: {
      const newState = produce(state, (draftState) => {
        draftState.BankDetailsLoading = LoadState.NotLoaded;
        draftState.BankDetails = initialBankDetailsState["BankDetails"];
      });
      return newState;
    }
    case CLEAR_BANK_DETAILS_STATE: {
      return initialBankDetailsState;
    }
    default: {
      return state;
    }
  }
};
