import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { RoleBasedCustomButton } from "../../../security/RoleBasedComponents/RoleBasedCustomButton/RoleBasedCustomButton";
import { MODULE_IDS } from "../../../constants/enums";
import { List, ListItem, ListItemButton, ListItemText } from "@mui/material";

interface IAddUserButtonProps {
  onClick: (type: "QUICK" | "FULL") => void;
}
export const AddUserButton: React.FC<IAddUserButtonProps> = ({ onClick }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleButtonClick = (type: "QUICK" | "FULL") => {
    onClick(type);
    handleClose();
  };

  return (
    <div>
      <RoleBasedCustomButton
        aria-describedby={id}
        moduleId={MODULE_IDS.USERS}
        variant="contained"
        onClick={handleClick}
      >
        Add Employee
      </RoleBasedCustomButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
      >
        <List>
          <ListItem disablePadding onClick={() => handleButtonClick("QUICK")}>
            <ListItemButton>
              <ListItemText primary="Quick Add" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding onClick={() => handleButtonClick("FULL")}>
            <ListItemButton>
              <ListItemText primary="Add Emplyee" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};
