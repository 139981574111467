import { lazy } from "react";
import { MODULE_IDS } from "../../constants/enums";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";

import { JOBS_APPROVAL_ROUTE, JOBS_ROUTE } from "./jobs.constants";

// customer
const ManageJobsList = Loadable(
  lazy(() =>
    import("./jobsList").then(({ JobsList }) => ({
      default: JobsList,
    })),
  ),
);

const ManageJobs = Loadable(
  lazy(() =>
    import("./ManageJobs").then(({ ManageJobs }) => ({
      default: ManageJobs,
    })),
  ),
);
const JobApprovalListTable = Loadable(
  lazy(() =>
    import("./JobApprovalListTable").then(({ JobApprovalListTable }) => ({
      default: JobApprovalListTable,
    })),
  ),
);

// customer

export const jobsRoutes = [
  {
    path: JOBS_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.JOBS]}>
        <ManageJobsList />
      </AuthorizedRoute>
    ),
  },
  {
    path: JOBS_APPROVAL_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.JOBS]}>
        <JobApprovalListTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${JOBS_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.JOBS]}>
        <ManageJobs />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${JOBS_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.JOBS]}>
        <ManageJobs />
      </AuthorizedRoute>
    ),
  },
];
