import { Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../components/LocationAutoComplete/LocationAutoComplete";
import { RightPanel } from "../../../../components/RightPanel";
import { TextFieldWithLabel } from "../../../../components/TextFieldWithLabel/TextFieldWithLabel";
import { CustomFormLabel } from "../../../../components/formsComponents";
import { error_message } from "../../../../constants/constants";
import { useDispatchWrapper } from "../../../../hooks";
import {
  ICustomerDeliveryAddress,
  upsertCustomerDeliveryAddressAsync,
} from "../../../../redux/customerDeliveryAddress";
import { useNavigate } from "react-router-dom";

interface IManageCustomerDeliveryAddress {
  open: boolean;
  data: ICustomerDeliveryAddress;
  onClose: () => void;
  onComplete: (data: ICustomerDeliveryAddress) => void;
}
export const ManageCustomerDeliveryAddress: React.FC<
  IManageCustomerDeliveryAddress
> = (props) => {
  const { open, data, onClose, onComplete } = props;
  const isUpdate = data?.customer_delivery_address_uuid ? true : false;
  const navigate = useNavigate();

  const dispatch = useDispatchWrapper();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.customer_delivery_address_line2) {
        errors.customer_delivery_address_line2 = error_message.required;
      }
      if (!values.customer_delivery_address_city) {
        errors.customer_delivery_address_city = error_message.required;
      }
      if (!values.customer_delivery_address_state) {
        errors.customer_delivery_address_state = error_message.required;
      }
      return errors;
    },
    onSubmit: async (values) => {
      dispatch(
        upsertCustomerDeliveryAddressAsync(
          {
            ...values,
            customer_name: values.customer_name,
            customer_uuid: values.customer_uuid,
          },
          (isSuccess, customerDeliveryAddress) => {
            if (isSuccess && customerDeliveryAddress) {
              onComplete(customerDeliveryAddress);
            }
          },
        ),
      );
    },
  });

  const handleLocation = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      customer_delivery_address_line2: data.address,
      customer_delivery_address_city: data.city,
      customer_delivery_address_state: data.state,
      customer_delivery_address_country: data.country,
      customer_delivery_address_pincode: data.postalCode,
    });
  };

  return (
    <RightPanel
      open={open}
      heading={isUpdate ? "Update Delivery Address" : "Create Delivery Address"}
      subHeading={`Customer: ${values.customer_name}`}
      width="50%"
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => (
        <Stack direction={"row"} spacing={2}>
          <Button variant="contained" type="button" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Stack>
      )}
    >
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading="Delivery Name"
              id="delivery_name"
              fullWidth
              value={values.delivery_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Add Location</CustomFormLabel>
            <LocationAutoComplete
              value={values.customer_delivery_address_line2}
              onLocationChange={handleLocation}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 1"}
              type="text"
              id="customer_delivery_address_line1"
              fullWidth
              value={values.customer_delivery_address_line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 2"}
              type="text"
              id="customer_delivery_address_line2"
              fullWidth
              value={values.customer_delivery_address_line2}
              onChange={handleChange}
              error={errors.customer_delivery_address_line2 ? true : false}
              helperText={errors.customer_delivery_address_line2}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"City"}
              type="text"
              id="customer_delivery_address_city"
              fullWidth
              value={values.customer_delivery_address_city}
              onChange={handleChange}
              error={errors.customer_delivery_address_city ? true : false}
              helperText={errors.customer_delivery_address_line2}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"State"}
              type="text"
              id="customer_delivery_address_state"
              fullWidth
              value={values.customer_delivery_address_state}
              onChange={handleChange}
              error={errors.customer_delivery_address_state ? true : false}
              helperText={errors.customer_delivery_address_line2}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Pin Code"}
              type="text"
              id="customer_delivery_address_pincode"
              fullWidth
              value={values.customer_delivery_address_pincode}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Country"}
              type="text"
              id="customer_delivery_address_country"
              fullWidth
              value={values.customer_delivery_address_country}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </form>
    </RightPanel>
  );
};
