import React from "react";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { Button, ButtonProps } from "@mui/material";
import { TextFieldWithLabel } from "../../../components/TextFieldWithLabel/TextFieldWithLabel";
import { useFormik } from "formik";

export const ButtonWithRemark: React.FC<{
  disabled: boolean;
  confirmButtonText: string;
  onConfirm: (remark: string) => void;
  variant?: ButtonProps["variant"];
}> = (props) => {
  const {
    disabled,
    confirmButtonText,
    onConfirm,
    variant = "contained",
  } = props;
  const [openDialog, setOpenDialog] = React.useState<boolean>(false);

  const { values, handleChange, errors, handleSubmit } = useFormik({
    initialValues: {
      remark: "",
    },
    validate: (values) => {
      let errors: any = {};
      if (values.remark === "") {
        errors.remark = "Remark is Required";
      }
      return errors;
    },
    onSubmit: (values) => {
      onConfirm(values.remark);
    },
  });

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Button
        disabled={disabled}
        variant={variant}
        type="button"
        onClick={handleOpenDialog}
      >
        {confirmButtonText}
      </Button>

      {openDialog && (
        <Dialog
          open={openDialog}
          title={`${confirmButtonText} Request`}
          subtitle=""
          onClose={handleCloseDialog}
          size="sm"
          actions={[
            {
              type: "button",
              label: "Cancel",
              variant: "text",
              onClick: handleCloseDialog,
            },
            {
              type: "submit",
              label: confirmButtonText,
              variant: "contained",
              onClick: handleSubmit,
            },
          ]}
        >
          <TextFieldWithLabel
            heading="Remarks"
            id="remark"
            fullWidth
            multiline
            rows={3}
            value={values.remark}
            onChange={handleChange}
            error={errors.remark ? true : false}
            helperText={errors.remark}
          />
        </Dialog>
      )}
    </form>
  );
};
