import { LoadState } from "../../constants/enums";
import {
  IJobCustomerBranch,
  IJobCustomerBranchState,
} from "./customBranch.types";

export const initialJobCustomerBranch: IJobCustomerBranch = {
  customer_branch_uuid: "",
  customer_branch_name: "",
  customer_uuid: "",
  customer_name: "",
  pan_no: null,
  customer_branch_gst_in: "",
  customer_branch_address_line1: null,
  customer_branch_address_line2: null,
  customer_branch_address_city: null,
  customer_branch_address_state: null,
  customer_branch_address_pincode: null,
  customer_branch_address_country: null,
  customer_branch_mobile: "",
  customer_branch_phone_number: "",
  customer_branch_website: "",
  customer_branch_mail_id: "",
  invoice_no_sequence: "",
};
export const initialJobCustomerBranchState: IJobCustomerBranchState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  error: null,
  job_customerBranch: initialJobCustomerBranch,
  customBranchLoading: LoadState.NotLoaded,
  customBranchError: null,
};
