import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_QUOTATION_STATE,
  CLEAR_SINGLE_QUOTATION,
  defaultQuotationState,
  FETCH_QUOTATION_LIST_FAILED,
  FETCH_QUOTATION_LIST_PROGRESS,
  FETCH_QUOTATION_LIST_SUCCESS,
  FETCH_SINGLE_QUOTATION_FAILED,
  FETCH_SINGLE_QUOTATION_PROGRESS,
  FETCH_SINGLE_QUOTATION_SUCCESS,
  IQuotationActions,
} from ".";

export const quotationReducer = (
  state: IStoreState["quotation"] = defaultQuotationState,
  action: IQuotationActions,
) => {
  switch (action.type) {
    case FETCH_QUOTATION_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.quotation_list.loading = LoadState.InProgress;
        draftState.quotation_list.data = [];
        draftState.quotation_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_QUOTATION_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.quotation_list.loading = LoadState.Loaded;
        draftState.quotation_list.data = list;
        draftState.quotation_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_QUOTATION_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.quotation_list.loading = LoadState.InProgress;
        draftState.quotation_list.data = [];
        draftState.quotation_list.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SINGLE_QUOTATION_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_quotation.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_QUOTATION_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_quotation.loading = LoadState.Loaded;
        draftState.single_quotation.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_QUOTATION_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_quotation.loading = LoadState.Failed;
        draftState.single_quotation.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_QUOTATION: {
      const newState = produce(state, (draftState) => {
        draftState.single_quotation.loading = LoadState.NotLoaded;
        draftState.single_quotation.data =
          defaultQuotationState["single_quotation"].data;
        draftState.single_quotation.error = null;
      });
      return newState;
    }
    case CLEAR_QUOTATION_STATE: {
      return defaultQuotationState;
    }
    default: {
      return state;
    }
  }
};
