import { LoadState } from "../../constants/enums";
import { IProject, IProjectState } from "./project.types";

export const initialProject: IProject = {
  project_uuid: "",
  project_name: "",
  legal_entity: null,
  project_manager_name: "",
  project_manager_uuid: "",
  finance_manager_uuid: "",
  finance_manager_name: "",
  start_date: null,
  end_date: null,
  remarks: null,
  status: "ACTIVE",
};

export const initialProjectState: IProjectState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
  project: initialProject,
  projectLoading: LoadState.NotLoaded,
  error: null,
};
