import { LoadState } from "../../constants/enums";
import {
  IJobCustomer,
  IJobCustomerState,
  IJobLoadCustomerData,
} from "./job_customer_types";

export const defaultCustomer: IJobCustomer = {
  customer_uuid: "",
  customer_name: "",
  is_billing_company: 0,
  customer_website: "",
  legal_entity: "",
  created_by_uuid: "",
  organization_type: "N/A",
  registration_type: null,
  status: "ACTIVE",
};

export const defaultJobLoadCustomerData: IJobLoadCustomerData = {
  customer_name: "",
  organization_type: null,
  customer_website: null,
  legal_entity: null,
  registration_type: null,
  customer_branch_name: "",
  customer_branch_gst_in: "",
  customer_branch_address_line1: null,
  customer_branch_address_line2: null,
  customer_branch_address_city: null,
  customer_branch_address_state: null,
  customer_branch_address_pincode: null,
  customer_branch_address_country: null,
  customer_branch_mobile: null,
  customer_branch_phone_number: null,
  customer_branch_website: null,
  customer_branch_mail_id: null,
  name: "",
  mail_id: null,
  pan_no: null,
  salutation: null,
  designation: null,
  contact_type: null,
  department: null,
  extension: null,
  company_landline: null,
  fax: null,
  website: null,
  dob: null,
  previous_organisation: null,
  skype_id: null,
  executive_location_line1: null,
  executive_location_line2: null,
  executive_location_city: null,
  executive_location_state: null,
  executive_location_pincode: null,
  executive_location_country: null,
  contact_number: null,
  delivery_name: null,
  customer_delivery_address_line1: null,
  customer_delivery_address_line2: null,
  customer_delivery_address_city: null,
  customer_delivery_address_state: null,
  customer_delivery_address_pincode: null,
  customer_delivery_address_country: null,
};

export const defaultJobCustomerState: IJobCustomerState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  customer: {
    data: defaultCustomer,
    loading: LoadState.NotLoaded,
    error: null,
  },
  loadCustomerData: {
    data: defaultJobLoadCustomerData,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
