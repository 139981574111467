import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_JOB,
  CLEAR_JOB_STATE,
  FETCH_JOB_APPROVAL_LIST_FAILED,
  FETCH_JOB_APPROVAL_LIST_PROGRESS,
  FETCH_JOB_APPROVAL_LIST_SUCCESS,
  FETCH_JOB_FAILED,
  FETCH_JOB_LIST_FAILED,
  FETCH_JOB_LIST_PROGRESS,
  FETCH_JOB_LIST_SUCCESS,
  FETCH_JOB_PROGRESS,
  FETCH_JOB_SUCCESS,
} from ".";
import { initialJobs, initialJobsState } from "./defaultState";
import { JobsActions } from ".";

export const jobsReducer = (
  state: IStoreState["jobs"] = initialJobsState,
  action: JobsActions,
) => {
  switch (action.type) {
    case FETCH_JOB_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.jobsList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_JOB_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.jobsList.loading = LoadState.Loaded;
        draftState.jobsList.list = data;
        draftState.jobsList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_JOB_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.jobsList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_JOB_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.jobsData.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_JOB_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.jobsData.loading = LoadState.Loaded;
        draftState.jobsData.data = data;
      });
      return newState;
    }
    case FETCH_JOB_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.jobsData.loading = LoadState.Failed;
        draftState.jobsData.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_JOB: {
      const newState = produce(state, (draftState) => {
        draftState.jobsData.loading = LoadState.NotLoaded;
        draftState.jobsData.data = initialJobs;
      });
      return newState;
    }
    case CLEAR_JOB_STATE: {
      return initialJobsState;
    }

    case FETCH_JOB_APPROVAL_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.jobs_approval_ist.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_JOB_APPROVAL_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.jobs_approval_ist.loading = LoadState.Loaded;
        draftState.jobs_approval_ist.list = data;
        draftState.jobs_approval_ist.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_JOB_APPROVAL_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.jobs_approval_ist.loading = LoadState.Failed;
      });
      return newState;
    }

    default: {
      return state;
    }
  }
};
