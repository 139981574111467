import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_SALE_ORDER_STATE,
  CLEAR_SINGLE_SALE_ORDER,
  defaultSaleOrderState,
  FETCH_SALE_ORDER_LIST_FAILED,
  FETCH_SALE_ORDER_LIST_PROGRESS,
  FETCH_SALE_ORDER_LIST_SUCCESS,
  FETCH_SINGLE_SALE_ORDER_FAILED,
  FETCH_SINGLE_SALE_ORDER_PROGRESS,
  FETCH_SINGLE_SALE_ORDER_SUCCESS,
  ISaleOrderActions,
} from ".";

export const saleOrderReducer = (
  state: IStoreState["sale_order"] = defaultSaleOrderState,
  action: ISaleOrderActions,
) => {
  switch (action.type) {
    case FETCH_SALE_ORDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.sale_order_list.loading = LoadState.InProgress;
        draftState.sale_order_list.data = [];
        draftState.sale_order_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_SALE_ORDER_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.sale_order_list.loading = LoadState.Loaded;
        draftState.sale_order_list.data = list;
        draftState.sale_order_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_SALE_ORDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.sale_order_list.loading = LoadState.InProgress;
        draftState.sale_order_list.data = [];
        draftState.sale_order_list.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SINGLE_SALE_ORDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_sale_order.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_SALE_ORDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_sale_order.loading = LoadState.Loaded;
        draftState.single_sale_order.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_SALE_ORDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_sale_order.loading = LoadState.Failed;
        draftState.single_sale_order.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_SALE_ORDER: {
      const newState = produce(state, (draftState) => {
        draftState.single_sale_order.loading = LoadState.NotLoaded;
        draftState.single_sale_order.data =
          defaultSaleOrderState["single_sale_order"].data;
        draftState.single_sale_order.error = null;
      });
      return newState;
    }
    case CLEAR_SALE_ORDER_STATE: {
      return defaultSaleOrderState;
    }
    default: {
      return state;
    }
  }
};
