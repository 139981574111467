import { LoadState } from "../../constants/enums";

export interface ICommonState {
  policies: {
    list: IPolicies[];
    loadState: LoadState;
  };
  generalHistory: {
    list: IGeneralHistory[];
    loading: LoadState;
  };
  recordCounts: {
    list: IRecordCount[];
    loading: LoadState;
  };
}

export interface IRecordCount {
  status: string;
  count: number;
}

export interface IPolicies {
  type: string;
  subTypes: {
    policy_id: number;
    name: string;
  }[];
}

export interface IGeneralHistory {
  insert_ts: string;
  [key: string]: any;
}

export const defaultCommonState: ICommonState = {
  policies: {
    list: [],
    loadState: LoadState.NotLoaded,
  },
  generalHistory: {
    list: [],
    loading: LoadState.NotLoaded,
  },
  recordCounts: {
    list: [],
    loading: LoadState.NotLoaded,
  },
};

export interface IMail {
  emails: string[];
  subject: string;
  body: string;
  templateName: string | null;
  objectVariables: any;
  cc: string[];
  bcc: string[];
  reply_to?: {
    email: string;
    name: string;
  };
  attachments?: {
    content: string;
    filename: string;
  }[];
}

export interface IQueryParams {
  status: string;
  page: number;
  rowsPerPage: number;
  columns: string[];
  value: string;
  fromDate?: string;
  toDate?: string;
}

export type IActiveStatus = "ACTIVE" | "INACTIVE";
