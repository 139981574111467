import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { uploadMultipleFile } from "../../components/FileUpload/utils";
import { getUniqueId } from "../../helpers";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import {
  IExpense,
  IExpenseApproval,
  IExpenseCategory,
  IGroupedApprovalExpense,
} from "./expenseModule.types";

// ********************** Fetch Expense List********************** //
export const FETCH_EXPENSE_LIST_PROGRESS = "FETCH_EXPENSE_LIST_PROGRESS";
export const FETCH_EXPENSE_LIST_SUCCESS = "FETCH_EXPENSE_LIST_SUCCESS";
export const FETCH_EXPENSE_LIST_FAILED = "FETCH_EXPENSE_LIST_FAILED";

export const fetchExpenseListProgress = () =>
  action(FETCH_EXPENSE_LIST_PROGRESS);
export const fetchExpenseListSuccess = (
  data: IExpense[],
  totalRecords: number,
) => action(FETCH_EXPENSE_LIST_SUCCESS, { data, totalRecords });
export const fetchExpenseListFailed = () => action(FETCH_EXPENSE_LIST_FAILED);

export const fetchExpenseListAsync =
  (
    queryParams: IQueryParams,
    reportUuid?: string,
    unreported?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let searchQuery = getSearchQuery(queryParams);
      if (reportUuid) {
        searchQuery += `&report_uuid=${reportUuid}`;
      }

      if (unreported) {
        searchQuery += `&unreported=${unreported}`;
      }

      dispatch(fetchExpenseListProgress());
      // let finalUrl = `/report/get-expense?pageNo=1&itemPerPage=10&unreported=UNREPORTED`;
      let finalUrl = `/expense/get-expense${searchQuery}`;
      const res = await api.get(finalUrl);
      const data: IExpense[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchExpenseListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchExpenseListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Fetch Single Expense ********************** //
export const FETCH_EXPENSE_PROGRESS = "FETCH_EXPENSE_PROGRESS";
export const FETCH_EXPENSE_SUCCESS = "FETCH_EXPENSE_SUCCESS";
export const FETCH_EXPENSE_FAILED = "FETCH_EXPENSE_FAILED";

export const fetchExpenseProgress = () => action(FETCH_EXPENSE_PROGRESS);
export const fetchExpenseSuccess = (data: IExpense) =>
  action(FETCH_EXPENSE_SUCCESS, { data });
export const fetchExpenseFailed = (errorMessage: string) =>
  action(FETCH_EXPENSE_FAILED, { errorMessage });

export const fetchExpenseAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchExpenseProgress());
      const res = await api.get(`/expense/get-expense?expense_uuid=${uuid}`);
      const data: IExpense[] = res.data.data;

      if (data.length > 0) {
        dispatch(fetchExpenseSuccess(data[0]));
      } else {
        dispatch(fetchExpenseFailed("Oops! We couldn't find any records."));
      }
    } catch (err: any) {
      dispatch(fetchExpenseFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Upsert Expense ********************** //
export const upsertExpenseAsync =
  (
    data: IExpense,
    onCallback: (isSuccess: boolean, customer?: IExpense) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        rowId,
        report_name,
        report_uuid,
        amount,
        requested_by_uuid,
        is_user_approver,
        approval_uuid,

        ...payload
      } = data;
      const asPayload = {
        title: data.expense_date,
      };
      // Corrected logic for mapping receipts
      const mappedReceipts =
        data.receipt?.map((item) =>
          typeof item === "string"
            ? { key: getUniqueId(), file: null, name: "", path: item }
            : item,
        ) || [];

      const { paths } = await uploadMultipleFile(
        mappedReceipts,
        "REPORT",
        asPayload,
      );
      dispatch(saveLoaderProgress());
      const res = await api.post("/expense/upsert-expense", {
        ...payload,
        receipt: paths,
      });

      const result = res.data.data[0];

      let message = "Expense created successfully!";
      onCallback(true, result);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Clear Expense ********************** //

export const CLEAR_EXPENSE = "CLEAR_EXPENSE";
export const CLEAR_EXPENSE_STATE = "CLEAR_EXPENSE_STATE";

// ********************** Fetch Expense Category List********************** //
export const FETCH_EXPENSE_CATEGORY_LIST_PROGRESS =
  "FETCH_EXPENSE_CATEGORY_LIST_PROGRESS";
export const FETCH_EXPENSE_CATEGORY_LIST_SUCCESS =
  "FETCH_EXPENSE_CATEGORY_LIST_SUCCESS";
export const FETCH_EXPENSE_CATEGORY_LIST_FAILED =
  "FETCH_EXPENSE_CATEGORY_LIST_FAILED";

export const fetchExpenseCategoryListProgress = () =>
  action(FETCH_EXPENSE_CATEGORY_LIST_PROGRESS);
export const fetchExpenseCategoryListSuccess = (
  data: IExpenseCategory[],
  totalRecords: number,
) => action(FETCH_EXPENSE_CATEGORY_LIST_SUCCESS, { data, totalRecords });
export const fetchExpenseCategoryListFailed = () =>
  action(FETCH_EXPENSE_CATEGORY_LIST_FAILED);

export const fetchExpenseCategoryListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchExpenseCategoryListProgress());
      let finalUrl = `/expense/get-expense-category${searchQuery}`;
      const res = await api.get(finalUrl);
      const data: IExpenseCategory[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchExpenseCategoryListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchExpenseCategoryListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Fetch Single Expense Category********************** //
export const FETCH_EXPENSE_CATEGORY_PROGRESS =
  "FETCH_EXPENSE_CATEGORY_PROGRESS";
export const FETCH_EXPENSE_CATEGORY_SUCCESS = "FETCH_EXPENSE_CATEGORY_SUCCESS";
export const FETCH_EXPENSE_CATEGORY_FAILED = "FETCH_EXPENSE_CATEGORY_FAILED";

export const fetchExpenseCategoryProgress = () =>
  action(FETCH_EXPENSE_CATEGORY_PROGRESS);
export const fetchExpenseCategorySuccess = (data: IExpenseCategory) =>
  action(FETCH_EXPENSE_CATEGORY_SUCCESS, { data });
export const fetchExpenseCategoryFailed = (errorMessage: string) =>
  action(FETCH_EXPENSE_CATEGORY_FAILED, { errorMessage });

export const fetchExpenseCategoryAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchExpenseCategoryProgress());
      const res = await api.get(
        `/expense/get-expense-category?expense_category_uuid=${uuid}`,
      );
      const data: IExpenseCategory[] = res.data.data;

      if (data.length > 0) {
        dispatch(fetchExpenseCategorySuccess(data[0]));
      } else {
        dispatch(
          fetchExpenseCategoryFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchExpenseCategoryFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Upsert Expense Category********************** //
export const upsertExpenseCategoryAsync =
  (
    data: IExpenseCategory,
    onCallback: (isSuccess: boolean, customer?: IExpense) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...payload } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/expense/upsert-expense-category", payload);

      const result = res.data.data[0];

      let message = "Category created successfully!";
      onCallback(true, result);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Clear Expense Category********************** //

export const CLEAR_EXPENSE_CATEGORY = "CLEAR_EXPENSE_CATEGORY";
export const clearExpenseCategory = () => action(CLEAR_EXPENSE_CATEGORY);

// ********************** Fetch Expense Report List********************** //
export const FETCH_EXPENSE_REPORT_LIST_PROGRESS =
  "FETCH_EXPENSE_REPORT_LIST_PROGRESS";
export const FETCH_EXPENSE_REPORT_LIST_SUCCESS =
  "FETCH_EXPENSE_REPORT_LIST_SUCCESS";
export const FETCH_EXPENSE_REPORT_LIST_FAILED =
  "FETCH_EXPENSE_REPORT_LIST_FAILED";

export const fetchExpenseReportListProgress = () =>
  action(FETCH_EXPENSE_REPORT_LIST_PROGRESS);
export const fetchExpenseReportListSuccess = (
  data: IExpenseApproval[],
  totalRecords: number,
) => action(FETCH_EXPENSE_REPORT_LIST_SUCCESS, { data, totalRecords });
export const fetchExpenseReportListFailed = () =>
  action(FETCH_EXPENSE_REPORT_LIST_FAILED);

export const fetchExpenseReportListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchExpenseReportListProgress());
      let finalUrl = `/report/get-report${searchQuery}`;
      const res = await api.get(finalUrl);
      const data: IExpenseApproval[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchExpenseReportListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchExpenseReportListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

// ********************** Fetch Approval BOM List********************** //
export const FETCH_APPROVAL_EXPENSE_LIST_PROGRESS =
  "FETCH_APPROVAL_EXPENSE_LIST_PROGRESS";
export const FETCH_APPROVAL_EXPENSE_LIST_SUCCESS =
  "FETCH_APPROVAL_EXPENSE_LIST_SUCCESS";
export const FETCH_APPROVAL_EXPENSE_LIST_FAILED =
  "FETCH_APPROVAL_EXPENSE_LIST_FAILED";

export const fetchApprovalExpenseListProgress = () =>
  action(FETCH_APPROVAL_EXPENSE_LIST_PROGRESS);
export const fetchApprovalExpenseListSuccess = (
  data: IGroupedApprovalExpense[],
  totalRecords: number,
) =>
  action(FETCH_APPROVAL_EXPENSE_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchApprovalExpenseListFailed = () =>
  action(FETCH_APPROVAL_EXPENSE_LIST_FAILED);

const execGroupDataByCreatedByName = (
  data: IExpenseApproval[],
): IGroupedApprovalExpense[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.created_by_name]) {
        //@ts-ignore
        acc[obj.created_by_name] = {
          id: obj.created_by_name,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.created_by_name].childs.push(obj);
      }
      return acc;
    }, {}),
  );
  return groupedData as IGroupedApprovalExpense[];
};
const execGroupDataByCreatedByProjectName = (
  data: IExpenseApproval[],
): IGroupedApprovalExpense[] => {
  const groupedData = Object.values(
    data.reduce((acc, obj) => {
      //@ts-ignore
      if (!acc[obj.project_name]) {
        //@ts-ignore
        acc[obj.project_name] = {
          id: obj.project_name,
          ...obj,
          childs: [obj],
        };
      } else {
        //@ts-ignore
        acc[obj.project_name].childs.push(obj);
      }
      return acc;
    }, {}),
  );
  return groupedData as IGroupedApprovalExpense[];
};

export const fetchApprovalExpenseListAsync =
  (
    queryParams: IQueryParams,
    expenseTypeValue: string,
    table_name: string,
    reportUuid?: string,
    unreported?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let searchQuery = getSearchQuery(queryParams);
      if (reportUuid) {
        searchQuery += `&report_uuid=${reportUuid}`;
      }

      if (unreported) {
        searchQuery += `&unreported=${unreported}`;
      }

      if (table_name) {
        searchQuery += `&table_name=${table_name}`;
      }

      dispatch(fetchApprovalExpenseListProgress());
      let finalUrl = `/approval/get-approval${searchQuery}`;
      const res = await api.get(finalUrl);
      const data: IExpenseApproval[] = res.data.data;
      const filteredData = !expenseTypeValue
        ? data.filter((item) => item.expense_type === "EXPENSE")
        : data.filter((item) => item.expense_type === expenseTypeValue);

      const finalData =
        expenseTypeValue === "EXPENSE"
          ? execGroupDataByCreatedByName(filteredData)
          : execGroupDataByCreatedByProjectName(filteredData);

      dispatch(
        fetchApprovalExpenseListSuccess(finalData, res.data.totalRecords),
      );
    } catch (err: any) {
      dispatch(fetchApprovalExpenseListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };
// ********************** Fetch Single Expense Report ********************** //
export const FETCH_EXPENSE_REPORT_PROGRESS = "FETCH_EXPENSE_REPORT_PROGRESS";
export const FETCH_EXPENSE_REPORT_SUCCESS = "FETCH_EXPENSE_REPORT_SUCCESS";
export const FETCH_EXPENSE_REPORT_FAILED = "FETCH_EXPENSE_REPORT_FAILED";

export const fetchExpenseReportProgress = () =>
  action(FETCH_EXPENSE_REPORT_PROGRESS);
export const fetchExpenseReportSuccess = (data: IExpenseApproval) =>
  action(FETCH_EXPENSE_REPORT_SUCCESS, { data });
export const fetchExpenseReportFailed = (errorMessage: string) =>
  action(FETCH_EXPENSE_REPORT_FAILED, { errorMessage });

// export const fetchExpenseReportAsync =
// 	(uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
// 	async (dispatch, getState) => {
// 		try {
// 			dispatch(fetchExpenseReportProgress());
// 			const res = await api.get(`/report/get-report?report_uuid=${uuid}`);
// 			const data: IExpenseApproval[] = res.data.data;

// 			if (data.length > 0) {
// 				dispatch(fetchExpenseReportSuccess(data[0]));
// 			} else {
// 				dispatch(
// 					fetchExpenseReportFailed("Oops! We couldn't find any records.")
// 				);
// 			}
// 		} catch (err: any) {
// 			dispatch(fetchExpenseReportFailed("Something went to be wrong!"));
// 			dispatch(
// 				showMessage({
// 					type: "error",
// 					message: err.response.data.message,
// 					displayAs: "snackbar",
// 				})
// 			);
// 		}
// 	};

// ********************** Upsert Expense Report********************** //
export const upsertExpenseReportAsync =
  (
    data: IExpenseApproval,
    onCallback: (isSuccess: boolean, customer?: IExpense) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        create_ts,
        insert_ts,
        rowId,
        approval_uuid,
        is_user_approver,
        requested_by_uuid,
        remark,
        ...payload
      } = data;

      dispatch(saveLoaderProgress());
      const res = await api.post("/report/upsert-report", payload);

      const result = res.data.data[0];

      let message = "Report created successfully!";
      onCallback(true, result);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

// ********************** Clear Expense Report********************** //

export const CLEAR_EXPENSE_REPORT = "CLEAR_EXPENSE_REPORT";
export const clearExpenseReport = () => action(CLEAR_EXPENSE_REPORT);

// Default Clear State

export const clearExpense = () => action(CLEAR_EXPENSE);
export const clearExpenseState = () => action(CLEAR_EXPENSE_STATE);
