import produce from "immer";
import {
  CLEAR_CUSTOMER_DISPATCH_ADDRESS,
  CLEAR_CUSTOMER_DISPATCH_ADDRESS_STATE,
  CustomerDispatchAddressActions,
  FETCH_CUSTOMER_DISPATCH_ADDRESS_FAILED,
  FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_FAILED,
  FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_PROGRESS,
  FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_SUCCESS,
  FETCH_CUSTOMER_DISPATCH_ADDRESS_PROGRESS,
  FETCH_CUSTOMER_DISPATCH_ADDRESS_SUCCESS,
  initialCustomerDispatchAddress,
  initialCustomerDispatchAddressState,
} from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";

export const customerDispatchAddressReducer = (
  state: IStoreState["customerDispatchAddress"] = initialCustomerDispatchAddressState,
  action: CustomerDispatchAddressActions,
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DISPATCH_ADDRESS_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_CUSTOMER_DISPATCH_ADDRESS_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customDispatchAddressLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DISPATCH_ADDRESS_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customDispatchAddressLoading = LoadState.Loaded;
        draftState.customerDispatchAddress = data;
      });
      return newState;
    }
    case FETCH_CUSTOMER_DISPATCH_ADDRESS_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customDispatchAddressLoading = LoadState.Failed;
        draftState.customDispatchAddressError = errorMessage;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_DISPATCH_ADDRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customDispatchAddressLoading = LoadState.NotLoaded;
        draftState.customerDispatchAddress = initialCustomerDispatchAddress;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_DISPATCH_ADDRESS_STATE: {
      return initialCustomerDispatchAddressState;
    }
    default: {
      return state;
    }
  }
};
