import { action } from "typesafe-actions";
import { ICompany, ICompanyState } from "./company.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IDate } from "../../components/Table/hooks/useDateFilter";

export const FETCH_COMPANY_LIST_PROGRESS = "FETCH_COMPANY_LIST_PROGRESS";
export const FETCH_COMPANY_LIST_SUCCESS = "FETCH_COMPANY_LIST_SUCCESS";
export const FETCH_COMPANY_LIST_FAILED = "FETCH_COMPANY_LIST_FAILED";

export const fetchCompanyListProgress = () =>
  action(FETCH_COMPANY_LIST_PROGRESS);
export const fetchCompanyListSuccess = (
  data: ICompanyState["list"],
  totalRecords: number,
) => action(FETCH_COMPANY_LIST_SUCCESS, { data, totalRecords });
export const fetchCompanyListFailed = () => action(FETCH_COMPANY_LIST_FAILED);

export const fetchCompanyListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCompanyListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/customer/get-customer?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/customer/get-customer?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&columns=${[
          searchType,
        ]}&value=${searchValue}`;
      }

      const res = await api.get(finalUrl);
      const data: ICompanyState["list"] = res.data.data;

      const totalRecords = res.data.totalRecords;

      dispatch(fetchCompanyListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCompanyListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertCompanyAsync =
  (
    data: ICompany,
    onCallback: (isSuccess: boolean, company?: ICompany) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { company_id, create_ts, insert_ts, ...rest } = data;
      const res = await api.post("/company/upsert-company", rest);
      let message = "Company saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_COMPANY_STATE = "CLEAR_COMPANY_STATE";
export const clearCompmanyState = () => action(CLEAR_COMPANY_STATE);
