import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IRenderingPartner } from "./rendering.types";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

//=============================== Rendering Partner List ===============================

export const FETCH_RENDERING_PARTNER_LIST_PROGRESS =
  "FETCH_RENDERING_PARTNER_LIST_PROGRESS";
export const FETCH_RENDERING_PARTNER_LIST_SUCCESS =
  "FETCH_RENDERING_PARTNER_LIST_SUCCESS";
export const FETCH_RENDERING_PARTNER_LIST_FAILED =
  "FETCH_RENDERING_PARTNER_LIST_FAILED";

export const fetchRenderingPartnerListProgress = () =>
  action(FETCH_RENDERING_PARTNER_LIST_PROGRESS);
export const fetchRenderingPartnerListSuccess = (
  list: IRenderingPartner[],
  totalRecords: number,
) => action(FETCH_RENDERING_PARTNER_LIST_SUCCESS, { list, totalRecords });
export const fetchRenderingPartnerListFailed = (errorMessage: string) =>
  action(FETCH_RENDERING_PARTNER_LIST_FAILED, { errorMessage });

export const fetchRenderingPartnerListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchRenderingPartnerListProgress());
      const res = await api.get(
        `/rendering/get-rendering-partners${searchQuery}`,
      );
      const data: IRenderingPartner[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchRenderingPartnerListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchRenderingPartnerListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Rendering Partner Single Record ===============================

export const FETCH_RENDERING_PARTNER_PROGRESS =
  "FETCH_RENDERING_PARTNER_PROGRESS";
export const FETCH_RENDERING_PARTNER_SUCCESS =
  "FETCH_RENDERING_PARTNER_SUCCESS";
export const FETCH_RENDERING_PARTNER_FAILED = "FETCH_RENDERING_PARTNER_FAILED";

export const fetchRenderingPartnerProgress = () =>
  action(FETCH_RENDERING_PARTNER_PROGRESS);
export const fetchRenderingPartnerSuccess = (
  payload: IRenderingPartner,
  totalRecords: number,
) => action(FETCH_RENDERING_PARTNER_SUCCESS, { payload });
export const fetchRenderingPartnerFailed = (errorMessage: string) =>
  action(FETCH_RENDERING_PARTNER_FAILED, { errorMessage });

export const fetchRenderingPartnerAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRenderingPartnerProgress());
      const res = await api.get(
        `/rendering/get-rendering-partners?rendering_partner_uuid=${uuid}`,
      );
      const data: IRenderingPartner = res.data.data[0] as IRenderingPartner;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchRenderingPartnerSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchRenderingPartnerFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Insert/Update Rendering Partner ===============================
export const upsertRenderingPartnerAysnc =
  (
    payload: IRenderingPartner,
    onCallback: (
      isSuccess: boolean,
      renderingPartner?: IRenderingPartner,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let { rendering_partner_id, create_ts, insert_ts, ...final_payload } =
        payload;

      const result = await api.post(
        "/rendering/upsert-rendering-partners",
        final_payload,
      ); //---> need to replace
      let message = "Rendering Partner is saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true, result.data?.data as IRenderingPartner);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Clear Rendering  ===============================
export const CLEAR_RENDERING_PARTNER_STATE = "CLEAR_RENDERING_PARTNER_STATE";
export const CLEAR_RENDERING_PARTNER = "CLEAR_RENDERING_PARTNER";

export const clearRenderingPartnerState = () =>
  action(CLEAR_RENDERING_PARTNER_STATE);
export const clearRenderingPartner = () => action(CLEAR_RENDERING_PARTNER);
