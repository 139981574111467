import produce from "immer";
import { JobCustomerActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  CLEAR_CUSTOMER_DATA,
  CLEAR_CUSTOMER_STATE,
  FETCH_CUSTOMER_FAILED,
  FETCH_CUSTOMER_LIST_FAILED,
  FETCH_CUSTOMER_LIST_PROGRESS,
  FETCH_CUSTOMER_LIST_SUCCESS,
  FETCH_CUSTOMER_PROGRESS,
  FETCH_CUSTOMER_SUCCESS,
} from "./job_customerActions";
import {
  defaultJobLoadCustomerData,
  defaultJobCustomerState,
} from "./defaultState";

export const job_customerReducer = (
  state: IStoreState["job_customer"] = defaultJobCustomerState,
  action: JobCustomerActions,
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CUSTOMER_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_CUSTOMER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customer.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customer.loading = LoadState.Loaded;
        draftState.customer.data = data;
      });
      return newState;
    }
    case FETCH_CUSTOMER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customer.loading = LoadState.Failed;
        draftState.customer.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_DATA: {
      const newState = produce(state, (draftState) => {
        draftState.loadCustomerData.loading = LoadState.NotLoaded;
        draftState.loadCustomerData.data = defaultJobLoadCustomerData;
      });
      return newState;
    }
    case CLEAR_CUSTOMER_STATE: {
      return defaultJobCustomerState;
    }
    default: {
      return state;
    }
  }
};
