import { LoadState } from "../../constants/enums";

export interface ICompanyState {
  list: ICompany[];
  totalRecords: number;
  loading: LoadState;
}

export interface ICompany {
  company_name: string;
  customer_website?: string;
  company_uuid: string | null;
  status: string;
  create_ts?: string;
  insert_ts?: string;
  company_id?: string;
}

export const initialCompanyState: ICompanyState = {
  list: [],
  totalRecords: 0,
  loading: LoadState.NotLoaded,
};
