import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  defaultRendering,
  defaultRenderingPartner,
  defaultRenderingState,
} from "./defaultState";
import { RenderingActions } from ".";
import {
  CLEAR_RENDERING_JOB,
  FETCH_RENDERING_JOB_FAILED,
  FETCH_RENDERING_JOB_LIST_FAILED,
  FETCH_RENDERING_JOB_LIST_PROGRESS,
  FETCH_RENDERING_JOB_LIST_SUCCESS,
  FETCH_RENDERING_JOB_PROGRESS,
  FETCH_RENDERING_JOB_SUCCESS,
} from "./renderingActions";
import {
  CLEAR_RENDERING_PARTNER,
  FETCH_RENDERING_PARTNER_FAILED,
  FETCH_RENDERING_PARTNER_LIST_FAILED,
  FETCH_RENDERING_PARTNER_LIST_PROGRESS,
  FETCH_RENDERING_PARTNER_LIST_SUCCESS,
  FETCH_RENDERING_PARTNER_PROGRESS,
  FETCH_RENDERING_PARTNER_SUCCESS,
} from "./renderingPartnerActions";

export const renderingReducer = (
  state: IStoreState["rendering"] = defaultRenderingState,
  action: RenderingActions,
) => {
  switch (action.type) {
    //=============================== Rendering List ===============================
    case FETCH_RENDERING_JOB_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rendering_list.loading = LoadState.InProgress;
        draftState.rendering_list.data = [];
        draftState.rendering_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_RENDERING_JOB_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_list.loading = LoadState.Loaded;
        draftState.rendering_list.data = list;
        draftState.rendering_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_RENDERING_JOB_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_list.loading = LoadState.Failed;
        draftState.rendering_list.data = [];
      });
      return newState;
    }

    // ---------------------------------- Rendering ----------------------------------
    case FETCH_RENDERING_JOB_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rendering.loading = LoadState.InProgress;
        draftState.rendering.data = defaultRendering;
      });
      return newState;
    }
    case FETCH_RENDERING_JOB_SUCCESS: {
      const { rendering } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering.loading = LoadState.Loaded;
        draftState.rendering.data = rendering;
      });
      return newState;
    }
    case FETCH_RENDERING_JOB_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering.loading = LoadState.Failed;
        draftState.rendering.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_RENDERING_JOB: {
      const newState = produce(state, (draftState) => {
        draftState.rendering.loading = LoadState.NotLoaded;
        draftState.rendering.data = defaultRendering;
        draftState.rendering.error = null;
      });
      return newState;
    }

    //=============================== Rendering Partner List ===============================
    case FETCH_RENDERING_PARTNER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rendering_partner_list.loading = LoadState.InProgress;
        draftState.rendering_partner_list.data = [];
        draftState.rendering_partner_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_RENDERING_PARTNER_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_partner_list.loading = LoadState.Loaded;
        draftState.rendering_partner_list.data = list;
        draftState.rendering_partner_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_RENDERING_PARTNER_LIST_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_partner_list.loading = LoadState.Failed;
        draftState.rendering_partner_list.data = [];
      });
      return newState;
    }

    // ---------------------------------- Rendering ----------------------------------
    case FETCH_RENDERING_PARTNER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.rendering_partner.loading = LoadState.InProgress;
        draftState.rendering_partner.data = defaultRenderingPartner;
      });
      return newState;
    }
    case FETCH_RENDERING_PARTNER_SUCCESS: {
      const { payload } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_partner.loading = LoadState.Loaded;
        draftState.rendering_partner.data = payload;
      });
      return newState;
    }
    case FETCH_RENDERING_PARTNER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.rendering_partner.loading = LoadState.Failed;
        draftState.rendering_partner.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_RENDERING_PARTNER: {
      const newState = produce(state, (draftState) => {
        draftState.rendering_partner.loading = LoadState.NotLoaded;
        draftState.rendering_partner.data = defaultRenderingPartner;
        draftState.rendering_partner.error = null;
      });
      return newState;
    }

    // ---------------------------------- Whole State ----------------------------------
    // case CLEAR_RENDERING_STATE: {
    // return defaultRenderingState;
    // }

    // ---------------------------------- Default ----------------------------------
    default: {
      return state;
    }
  }
};
