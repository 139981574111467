import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { Dialog } from "../../components/Dialogs/Dialog";
import LoadingDialog from "../../components/Dialogs/LoadingDialog";
import {
  CustomFormLabel,
  CustomTextField,
} from "../../components/formsComponents";
import { error_message } from "../../constants/constants";
import { useDispatchWrapper } from "../../hooks";
import { IBankDetails } from "../../redux/bankDetails/bankDetails.types";
import {
  clearBankDetails,
  upsertBankDetailsAsync,
} from "../../redux/bankDetails/bankDetailsActions";
import { showMessage } from "../../redux/messages/messagesActions";
import { useNavigate } from "react-router-dom";

export interface ManageBankDetailsProps {
  open: boolean;
  data: IBankDetails;
  onClose: () => void;
  onComplete: (data: IBankDetails) => void;
}

export const ManageBankDetails: React.FC<ManageBankDetailsProps> = (props) => {
  const { open, data, onClose, onComplete } = props;
  const isUpdate = data?.bank_details_uuid ? true : false;
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = React.useState<boolean>(false);
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.customer_name) {
        errors.customer_name = error_message.required;
      }
      if (!values.bank_name) {
        errors.bank_name = error_message.required;
      }
      if (!values.branch) {
        errors.branch = error_message.required;
      }
      if (!values.account_no) {
        errors.account_no = error_message.required;
      }
      if (!values.ifsc_code) {
        errors.ifsc_code = error_message.required;
      }
      return errors;
    },
    onSubmit: (values) => {
      setSaveLoading(true);
      dispatch(
        upsertBankDetailsAsync(
          {
            ...values,
            customer_name: values.customer_name,
            customer_uuid: values.customer_uuid,
          },
          (isSuccess, BankDetails) => {
            if (isSuccess && BankDetails) {
              dispatch(
                showMessage({
                  displayAs: "snackbar",
                  message: "Task updated successfully.",
                  type: "success",
                }),
              );
              onComplete(BankDetails);
            }
            setSaveLoading(false);
          },
        ),
      );
    },
  });

  React.useEffect(() => {
    return () => {
      dispatch(clearBankDetails());
    };
  });

  return (
    <Dialog
      open={open}
      title={isUpdate ? "Edit Bank Details" : "Add Bank Details"}
      subtitle={`Customer: ${values.customer_name}`}
      onClose={onClose}
      size="sm"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        {
          type: "button",
          label: "Close",
          variant: "text",
          onClick: onClose,
        },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
        },
      ]}
    >
      <Grid container spacing={1}>
        {/* <Grid item xs={12} lg={12}>
          <CustomerAutoSearch
            label="Customer Name"
            value={{
              customer_uuid: values.customer_uuid,
              customer_name: values.customer_name,
            }}
            onSelect={(value) => {
              setValues({
                ...values,
                customer_uuid: value.customer_uuid as string,
                customer_name: value.customer_name,
              });
            }}
            disabled
          />
        </Grid> */}
        <Grid item xs={12} lg={12}>
          <CustomFormLabel>Bank Name</CustomFormLabel>
          <CustomTextField
            name="bank_name"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.bank_name}
            error={errors.bank_name ? true : false}
            helperText={errors.bank_name}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12}>
          <CustomFormLabel>Branch Name</CustomFormLabel>
          <CustomTextField
            name="branch"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.branch}
            error={errors.branch ? true : false}
            helperText={errors.branch}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>Account No.</CustomFormLabel>
          <CustomTextField
            name="account_no"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.account_no}
            error={errors.account_no ? true : false}
            helperText={errors.account_no}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <CustomFormLabel>IFSC Code</CustomFormLabel>
          <CustomTextField
            name="ifsc_code"
            variant="outlined"
            size="small"
            type="text"
            fullWidth
            value={values.ifsc_code}
            error={errors.ifsc_code ? true : false}
            helperText={errors.ifsc_code}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      <LoadingDialog open={saveLoading} />
    </Dialog>
  );
};
