import { CustomFormLabel, CustomTextField } from "../formsComponents";
import { ITextFieldWithLabelProps } from "./TextFieldWithLabel.types";

export const TextFieldWithLabel: React.FC<ITextFieldWithLabelProps> = (
  props,
) => {
  return (
    <>
      <CustomFormLabel>{props.heading}</CustomFormLabel>
      <CustomTextField
        {...props}
        variant="outlined"
        size="small"
        error={props.error ? true : false}
        helperText={props.error ? props.helperText : ''}
      />
    </>
  );
};
