import { lazy } from "react";
import { MODULE_IDS } from "../../constants/enums";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { VENDOR_ROUTE } from "./vendor.constants";

// customer
const ManageVendorList = Loadable(
  lazy(() =>
    import("./VendorList").then(({ VendorList }) => ({
      default: VendorList,
    })),
  ),
);

const ManageVendor = Loadable(
  lazy(() =>
    import("./ManageVendor").then(({ ManageVendor }) => ({
      default: ManageVendor,
    })),
  ),
);

const VendorDetailsWithTabs = Loadable(
  lazy(() =>
    import("./VendorDetailsWithTabs").then(({ VendorDetailsWithTabs }) => ({
      default: VendorDetailsWithTabs,
    })),
  ),
);

// customer

export const vendorRoutes = [
  {
    path: VENDOR_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.VENDOR]}>
        <ManageVendorList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${VENDOR_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.VENDOR]}>
        <ManageVendor />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${VENDOR_ROUTE}/manage/:vendorUUID`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.VENDOR]}>
        <ManageVendor />
      </AuthorizedRoute>
    ),
  },
];
