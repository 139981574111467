import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IRendering, IRenderingPartner } from "./rendering.types";
import { IFileUpload } from "../../components/FileUpload/FileUpload.type";
import { uploadMultipleFiles } from "../../helpers/uploadFile";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";

//=============================== Rendering List ===============================

export const FETCH_RENDERING_JOB_LIST_PROGRESS =
  "FETCH_RENDERING_JOB_LIST_PROGRESS";
export const FETCH_RENDERING_JOB_LIST_SUCCESS =
  "FETCH_RENDERING_JOB_LIST_SUCCESS";
export const FETCH_RENDERING_JOB_LIST_FAILED =
  "FETCH_RENDERING_JOB_LIST_FAILED";

export const fetchRenderingJobListProgress = () =>
  action(FETCH_RENDERING_JOB_LIST_PROGRESS);
export const fetchRenderingJobListSuccess = (
  list: IRendering[],
  totalRecords: number,
) => action(FETCH_RENDERING_JOB_LIST_SUCCESS, { list, totalRecords });
export const fetchRenderingJobListFailed = (errorMessage: string) =>
  action(FETCH_RENDERING_JOB_LIST_FAILED, { errorMessage });

export const fetchRenderingJobListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchRenderingJobListProgress());
      const res = await api.get(`/rendering/get-rendering${searchQuery}`);
      const data: IRendering[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchRenderingJobListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchRenderingJobListFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Rendering Single Record ===============================

export const FETCH_RENDERING_JOB_PROGRESS = "FETCH_RENDERING_JOB_PROGRESS";
export const FETCH_RENDERING_JOB_SUCCESS = "FETCH_RENDERING_JOB_SUCCESS";
export const FETCH_RENDERING_JOB_FAILED = "FETCH_RENDERING_JOB_FAILED";

export const fetchRenderingJobProgress = () =>
  action(FETCH_RENDERING_JOB_PROGRESS);
export const fetchRenderingJobSuccess = (rendering: IRendering) =>
  action(FETCH_RENDERING_JOB_SUCCESS, { rendering });
export const fetchRenderingJobFailed = (errorMessage: string) =>
  action(FETCH_RENDERING_JOB_FAILED, { errorMessage });

export const fetchRenderingJobAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchRenderingJobProgress());
      const res = await api.get(
        `/rendering/get-rendering?rendering_uuid=${uuid}`,
      );
      const data: IRendering = res.data.data[0] as IRendering;
      dispatch(fetchRenderingJobSuccess(data));
    } catch (err: any) {
      dispatch(fetchRenderingJobFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Insert/Update Rendering ===============================
export const upsertRenderingJobAysnc =
  (
    rendering: IRendering,
    files: IFileUpload[],
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      let { rendering_id, create_ts, insert_ts, ...payload } = rendering;

      if (files.length > 0) {
        const asPayload = {
          project_name: rendering.project_name,
        };
        const result = await uploadMultipleFiles(files, "PROJECT", asPayload);
        payload.attachments = Object.assign({}, result);
      }

      const result = await api.post("/rendering/upsert-rendering", payload);
      let message = "Rendering is saved successfully.";
      dispatch(
        showMessage({
          type: "success",
          message: message,
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Clear Rendering  ===============================
export const CLEAR_RENDERING_JOB_STATE = "CLEAR_RENDERING_JOB_STATE";
export const CLEAR_RENDERING_JOB = "CLEAR_RENDERING_JOB";

export const clearRenderingJobState = () => action(CLEAR_RENDERING_JOB_STATE);
export const clearRenderingJob = () => action(CLEAR_RENDERING_JOB);
