import { lazy } from "react";
import { MODULE_IDS } from "../../constants/enums";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import {
  PURCHASE_ORDER_APPROVAL_ROUTE,
  PURCHASE_ORDER_ROUTE,
} from "./PurchaseOrder.constants";

//************************ Purchase Order Imports ***************************/
const PurchaseOrderTable = Loadable(
  lazy(() =>
    import("./PurchaseOrderTable").then(({ PurchaseOrderTable }) => ({
      default: PurchaseOrderTable,
    })),
  ),
);

const ManagePurchaseOrder = Loadable(
  lazy(() =>
    import("./ManagePurchaseOrder").then(({ ManagePurchaseOrder }) => ({
      default: ManagePurchaseOrder,
    })),
  ),
);
const PuchaseOrderApprovals = Loadable(
  lazy(() =>
    import("./PuchaseOrderApprovals").then(({ PuchaseOrderApprovals }) => ({
      default: PuchaseOrderApprovals,
    })),
  ),
);

//************************ Purchase Order Routes ***************************/
export const purchaseOrderRoutes = [
  {
    path: PURCHASE_ORDER_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_ORDER]}>
        <PurchaseOrderTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: PURCHASE_ORDER_APPROVAL_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_ORDER]}>
        <PuchaseOrderApprovals />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PURCHASE_ORDER_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_ORDER]}>
        <ManagePurchaseOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PURCHASE_ORDER_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_ORDER]}>
        <ManagePurchaseOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${PURCHASE_ORDER_ROUTE}/duplicate/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.PURCHASE_ORDER]}>
        <ManagePurchaseOrder isDuplicate={true} />
      </AuthorizedRoute>
    ),
  },
];
