import { lazy } from "react";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { SALE_ORDER_ROUTE } from "./SaleOrder.constants";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { MODULE_IDS } from "../../constants/enums";

//************************ Purchase Order Imports ***************************/
const SaleOrderTable = Loadable(
  lazy(() =>
    import("./SaleOrderTable").then(({ SaleOrderTable }) => ({
      default: SaleOrderTable,
    })),
  ),
);

const ManageSaleOrder = Loadable(
  lazy(() =>
    import("./ManageSaleOrder").then(({ ManageSaleOrder }) => ({
      default: ManageSaleOrder,
    })),
  ),
);

//************************ Purchase Order Routes ***************************/
export const saleOrderRoutes = [
  {
    path: SALE_ORDER_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.SALE_ORDER]}>
        <SaleOrderTable />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${SALE_ORDER_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.SALE_ORDER]}>
        <ManageSaleOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${SALE_ORDER_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.SALE_ORDER]}>
        <ManageSaleOrder />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${SALE_ORDER_ROUTE}/duplicate/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.SALE_ORDER]}>
        <ManageSaleOrder isDuplicate />
      </AuthorizedRoute>
    ),
  },
];
