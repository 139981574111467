import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { getSearchQuery } from "../common/helpers";
import { IQueryParams } from "../common/common.types";
import { uploadFile } from "../../helpers/uploadFile";
import { ISaleOrder } from "./sale-order.types";

//=============================== Goods Receiving Note List ===============================
export const FETCH_SALE_ORDER_LIST_PROGRESS = "FETCH_SALE_ORDER_LIST_PROGRESS";
export const FETCH_SALE_ORDER_LIST_SUCCESS = "FETCH_SALE_ORDER_LIST_SUCCESS";
export const FETCH_SALE_ORDER_LIST_FAILED = "FETCH_SALE_ORDER_LIST_FAILED";

export const fetchSaleOrderListProgress = () =>
  action(FETCH_SALE_ORDER_LIST_PROGRESS);
export const fetchSaleOrderListSuccess = (
  list: ISaleOrder[],
  totalRecords: number,
) => action(FETCH_SALE_ORDER_LIST_SUCCESS, { list, totalRecords });
export const fetchSaleOrderListFailed = () =>
  action(FETCH_SALE_ORDER_LIST_FAILED);

export const fetchSaleOrderListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchSaleOrderListProgress());

      const res = await api.get(
        `/sale_order/get-sale-order${searchQuery}&status=ACTIVE&cancelled_status=TRUE`,
      );
      const data: ISaleOrder[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchSaleOrderListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchSaleOrderListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err?.response?.data?.message || "Unknown Error Occurred",
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Fetch Single Goods Receiving Note ===============================
export const FETCH_SINGLE_SALE_ORDER_PROGRESS =
  "FETCH_SINGLE_SALE_ORDER_PROGRESS";
export const FETCH_SINGLE_SALE_ORDER_SUCCESS =
  "FETCH_SINGLE_SALE_ORDER_SUCCESS";
export const FETCH_SINGLE_SALE_ORDER_FAILED = "FETCH_SINGLE_SALE_ORDER_FAILED";

export const fetchSingleSaleOrderProgress = () =>
  action(FETCH_SINGLE_SALE_ORDER_PROGRESS);
export const fetchSingleSaleOrderSuccess = (data: ISaleOrder) =>
  action(FETCH_SINGLE_SALE_ORDER_SUCCESS, { data });
export const fetchSingleSaleOrderFailed = (errorMessage: string) =>
  action(FETCH_SINGLE_SALE_ORDER_FAILED, { errorMessage });

export const fetchSingleSaleOrderAsync =
  (uuid: string): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchSingleSaleOrderProgress());
      const res = await api.get(
        `/sale_order/get-sale-order?sale_order_uuid=${uuid}`,
      );
      const data: ISaleOrder[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchSingleSaleOrderSuccess(data[0]));
      } else {
        dispatch(
          fetchSingleSaleOrderFailed("Oops! We couldn't find any records."),
        );
      }
    } catch (err: any) {
      dispatch(fetchSingleSaleOrderFailed("Something went to be wrong!"));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

//=============================== Upsert Single Goods Receiving Note ===============================
export const upsertSingleSaleOrderAsync =
  (
    payload: ISaleOrder,
    onCallback: (isSuccess: boolean, grnUUID?: string) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());

      const { create_ts, insert_ts, combined_sale_order_no, ...restPayload } =
        payload;
      const res = await api.post("/sale_order/upsert-sale-order", restPayload);

      const responseData: ISaleOrder = res.data.data;

      onCallback(true, responseData.sale_order_uuid as string);
      dispatch(
        showMessage({
          type: "success",
          message: "Sale Order saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Delete Single Sale Order ===============================
export const deleteSingleSaleOrderAsync =
  (
    uuid: string,
    onCallback: (isSuccess: boolean) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      await api.delete("/sale_order/delete-sale-order", {
        data: { sale_order_uuid: uuid },
      });
      dispatch(
        showMessage({
          type: "success",
          message: "Sale Order deleted successfully!",
          displayAs: "snackbar",
        }),
      );
      onCallback(true);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

//=============================== Clear State ===============================
export const CLEAR_SINGLE_SALE_ORDER = "CLEAR_SINGLE_SALE_ORDER";
export const CLEAR_SALE_ORDER_STATE = "CLEAR_SALE_ORDER_STATE";
export const clearSaleOrderSync = () => action(CLEAR_SINGLE_SALE_ORDER);
export const clearSaleOrderStateSync = () => action(CLEAR_SALE_ORDER_STATE);
