import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { defaultCostingSheet, defaultCostingSheetState } from "./defaultState";
import {
  CLEAR_APPROVAL_COSTING_SHEET,
  CLEAR_COSTING_SHEET,
  CLEAR_COSTING_SHEET_STATE,
  CostingSheetActions,
  FETCH_APPROVAL_COSTING_SHEET_LIST_FAILED,
  FETCH_APPROVAL_COSTING_SHEET_LIST_PROGRESS,
  FETCH_APPROVAL_COSTING_SHEET_LIST_SUCCESS,
  FETCH_COSTING_SHEET_BY_ENQ_PROGRESS,
  FETCH_COSTING_SHEET_BY_ENQ_SUCCESS,
  FETCH_COSTING_SHEET_FAILED,
  FETCH_COSTING_SHEET_LIST_FAILED,
  FETCH_COSTING_SHEET_LIST_PROGRESS,
  FETCH_COSTING_SHEET_LIST_SUCCESS,
  FETCH_COSTING_SHEET_PROGRESS,
  FETCH_COSTING_SHEET_SUCCESS,
  FETCH_COSTING_SHEET__BY_ENQ_FAILED,
} from ".";

export const costingSheetReducer = (
  state: IStoreState["costingSheet"] = defaultCostingSheetState,
  action: CostingSheetActions,
) => {
  switch (action.type) {
    case FETCH_COSTING_SHEET_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COSTING_SHEET_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_COSTING_SHEET_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_COSTING_SHEET_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.costingSheet.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COSTING_SHEET_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.costingSheet.loading = LoadState.Loaded;
        draftState.costingSheet.data = data;
      });
      return newState;
    }
    case FETCH_COSTING_SHEET_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.costingSheet.loading = LoadState.Failed;
        draftState.costingSheet.error = errorMessage;
      });
      return newState;
    }
    case FETCH_COSTING_SHEET_BY_ENQ_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.costingSheet.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_COSTING_SHEET_BY_ENQ_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.costingSheet.loading = LoadState.Loaded;
        draftState.list.data = data;
      });
      return newState;
    }
    case FETCH_COSTING_SHEET__BY_ENQ_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.costingSheet.loading = LoadState.Failed;
        draftState.costingSheet.error = errorMessage;
      });
      return newState;
    }

    case FETCH_APPROVAL_COSTING_SHEET_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.approvalCostingSheetList.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_APPROVAL_COSTING_SHEET_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.approvalCostingSheetList.loading = LoadState.Loaded;
        draftState.approvalCostingSheetList.data = data;
        draftState.approvalCostingSheetList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_APPROVAL_COSTING_SHEET_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.approvalCostingSheetList.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_APPROVAL_COSTING_SHEET: {
      const newState = produce(state, (draftState) => {
        draftState.approvalCostingSheetList.loading = LoadState.NotLoaded;
        draftState.approvalCostingSheetList.data = [];
        draftState.approvalCostingSheetList.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_COSTING_SHEET: {
      const newState = produce(state, (draftState) => {
        draftState.costingSheet.loading = LoadState.NotLoaded;
        draftState.costingSheet.data = defaultCostingSheet;
      });
      return newState;
    }
    case CLEAR_COSTING_SHEET_STATE: {
      return defaultCostingSheetState;
    }
    default: {
      return state;
    }
  }
};
