import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { action } from "typesafe-actions";
import { api } from "../../api/api";
import { IQueryParams } from "../common/common.types";
import { getSearchQuery } from "../common/helpers";
import { IStoreState } from "../initialStoreState";
import { showMessage } from "../messages/messagesActions";
import { IBankDetails, IBankDetailsState } from "./bankDetails.types";

export const FETCH_BANK_DETAILS_LIST_PROGRESS =
  "FETCH_BANK_DETAILS_LIST_PROGRESS";
export const FETCH_BANK_DETAILS_LIST_SUCCESS =
  "FETCH_BANK_DETAILS_LIST_SUCCESS";
export const FETCH_BANK_DETAILS_LIST_FAILED = "FETCH_BANK_DETAILS_LIST_FAILED";

export const fetchBankDetailsListProgress = () =>
  action(FETCH_BANK_DETAILS_LIST_PROGRESS);
export const fetchBankDetailsListSuccess = (
  data: IBankDetailsState["list"],
  totalRecords: number,
) => action(FETCH_BANK_DETAILS_LIST_SUCCESS, { data, totalRecords });
export const fetchBankDetailsListFailed = () =>
  action(FETCH_BANK_DETAILS_LIST_FAILED);

export const fetchBankDetailsListAsync =
  (queryParams: IQueryParams): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchBankDetailsListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(`/customer/get-bank-details${searchQuery}`);
      const data: IBankDetails[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchBankDetailsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchBankDetailsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_CUSTOMER_BANK_DETAILS_LIST_PROGRESS =
  "FETCH_CUSTOMER_BANK_DETAILS_LIST_PROGRESS";
export const FETCH_CUSTOMER_BANK_DETAILS_LIST_SUCCESS =
  "FETCH_CUSTOMER_BANK_DETAILS_LIST_SUCCESS";
export const FETCH_CUSTOMER_BANK_DETAILS_LIST_FAILED =
  "FETCH_CUSTOMER_BANK_DETAILS_LIST_FAILED";

export const fetchCustomerBankDetailsListProgress = () =>
  action(FETCH_CUSTOMER_BANK_DETAILS_LIST_PROGRESS);
export const fetchCustomerBankDetailsListSuccess = (
  data: IBankDetailsState["list"],
  totalRecords: number,
) => action(FETCH_CUSTOMER_BANK_DETAILS_LIST_SUCCESS, { data, totalRecords });
export const fetchCustomerBankDetailsListFailed = () =>
  action(FETCH_CUSTOMER_BANK_DETAILS_LIST_FAILED);

export const fetchCustomerBankDetailsListAsync =
  (
    queryParams: IQueryParams,
    customerUUID?: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchCustomerBankDetailsListProgress());
      const searchQuery = getSearchQuery(queryParams);
      const res = await api.get(
        `/customer/get-bank-details${searchQuery}&customer_uuid=${customerUUID}`,
      );
      const data: IBankDetails[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchCustomerBankDetailsListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCustomerBankDetailsListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertBankDetailsAsync =
  (
    data: IBankDetails,
    // file: any | null,
    onCallback: (isSuccess: boolean, BankDetails?: IBankDetails) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const { create_ts, insert_ts, rowId, ...rest } = data;
      const res = await api.post("/customer/upsert-bank-details", rest);
      let message = "Bank Details saved successfully!";
      onCallback(true, res.data.data);
      dispatch(
        showMessage({
          type: "success",
          displayAs: "snackbar",
          message: message,
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_BANK_DETAILS = "CLEAR_BANK_DETAILS";
export const clearBankDetails = () => action(CLEAR_BANK_DETAILS);
export const CLEAR_BANK_DETAILS_STATE = "CLEAR_BANK_DETAILS_STATE";
export const clearBankDetailsState = () => action(CLEAR_BANK_DETAILS_STATE);
