import { LoadState } from "../../constants/enums";
import { ICustomer, ICustomerBranch, ICustomerState } from "./customer.types";

export const defaultCustomer: ICustomer = {
  customer_uuid: "",
  customer_name: "",
  customer_website: "",
  legal_entity: "",
  created_by_uuid: "",
  organization_type: "",
  status: "ACTIVE",
  create_ts: "",
  insert_ts: "",
};

export const defaultCustomerBranch: ICustomerBranch = {
  customer_branch_uuid: null,
  customer_uuid: null,
  customer_name: null,
  branch_name: null,
  legal_entity: null,
  is_customer_supplier: null,
  alternate_name: null,
  region: null,
  sub_region_or_country: null,
  city: null,
  pin_zip_code: null,
  industry: null,
  billing_address_line1: null,
  billing_address_line2: null,
  billing_city: null,
  billing_state: null,
  billing_pincode: null,
  billing_country: null,
  delivery_address_line1: null,
  delivery_address_line2: null,
  delivery_address_city: null,
  delivery_address_state: null,
  delivery_address_pincode: null,
  delivery_address_country: null,
  import_export_code_iec: null,
  pan_no: null,
  gst_no: null,
  service_tax_category: null,
  registered_msme: null,
  status: "ACTIVE",
};

export const defaultCustomerState: ICustomerState = {
  list: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  customer: {
    data: defaultCustomer,
    loading: LoadState.NotLoaded,
    error: null,
  },
  customerBranchList: {
    data: [],
    totalRecords: 0,
    loading: LoadState.NotLoaded,
    error: null,
  },
  customerBranch: {
    data: defaultCustomerBranch,
    loading: LoadState.NotLoaded,
    error: null,
  },
};
