import { action } from "typesafe-actions";
import { IQueryParams } from "../common/common.types";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { getSearchQuery } from "../common/helpers";
import { api } from "../../api/api";
import {
  saveLoaderCompleted,
  saveLoaderProgress,
  showMessage,
} from "../messages/messagesActions";
import { ICustomerVendorAttachment } from "./customerVendorAttachment.types";
import { uploadFile } from "../../helpers/uploadFile";

export const FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_PROGRESS =
  "FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_PROGRESS";
export const FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_SUCCESS =
  "FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_SUCCESS";
export const FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_FAILED =
  "FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_FAILED";

export const fetchCustomerVendorAttachmentListProgress = () =>
  action(FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_PROGRESS);
export const fetchCustomerVendorAttachmentListSuccess = (
  data: ICustomerVendorAttachment[],
  totalRecords: number,
) =>
  action(FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_SUCCESS, { data, totalRecords });
export const fetchCustomerVendorAttachmentListFailed = () =>
  action(FETCH_CUSTOMER_VENDOR_ATTACHMENT_LIST_FAILED);

export const fetchCustomerVendorAttachmentListAsync =
  (
    queryParams: IQueryParams,
    customerUUID: string,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const searchQuery = getSearchQuery(queryParams);
      dispatch(fetchCustomerVendorAttachmentListProgress());
      let finalUrl = `/customer/get-customer-attachments${searchQuery}&customer_uuid=${customerUUID}&status=ACTIVE`;
      const res = await api.get(finalUrl);
      const data: ICustomerVendorAttachment[] = res.data.data;
      const totalRecords = res.data.totalRecords;
      dispatch(fetchCustomerVendorAttachmentListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchCustomerVendorAttachmentListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertCustomerVendorAttachmentAsync =
  (
    payload: ICustomerVendorAttachment,
    file: any | null,
    onCallback: (
      isSuccess: boolean,
      returnedData?: ICustomerVendorAttachment,
    ) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(saveLoaderProgress());
      let path = "";
      const asPayload = {
        customer_name: payload.customer_name,
      };
      path = await uploadFile(file, "CUSTOMER", payload.link || "", asPayload);
      const { create_ts, insert_ts, rowId, ...rest } = payload;
      const res = await api.post("/customer/upsert-customer-attachments", {
        ...rest,
        link: path,
      });
      const data = res.data.data;
      onCallback(true, data);
      dispatch(
        showMessage({
          type: "success",
          message: "Document saved successfully!",
          displayAs: "snackbar",
        }),
      );
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    } finally {
      dispatch(saveLoaderCompleted());
    }
  };

export const CLEAR_CUSTOMER_VENDOR_ATTACHMENT =
  "CLEAR_CUSTOMER_VENDOR_ATTACHMENT";
export const CLEAR_CUSTOMER_VENDOR_ATTACHMENT_STATE =
  "CLEAR_CUSTOMER_VENDOR_ATTACHMENT_STATE";

export const clearCustomerVendorAttachment = () =>
  action(CLEAR_CUSTOMER_VENDOR_ATTACHMENT);
export const clearCustomerVendorAttachmentState = () =>
  action(CLEAR_CUSTOMER_VENDOR_ATTACHMENT_STATE);
