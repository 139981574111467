import { Button, Grid, Stack } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../../components/LocationAutoComplete/LocationAutoComplete";
import { PhoneTextField } from "../../../../components/PhoneTextField/PhoneTextField";
import { RightPanel } from "../../../../components/RightPanel";
import { TextFieldWithLabel } from "../../../../components/TextFieldWithLabel/TextFieldWithLabel";
import { PageContainer } from "../../../../components/container/PageContainer";
import {
  ControlledCustomSelect,
  CustomFormLabel,
} from "../../../../components/formsComponents";
import { contact_type, error_message } from "../../../../constants/constants";
import { useDispatchWrapper, useLoggedInUserInfo } from "../../../../hooks";

import {
  IJobContact,
  upsertContactsAsync,
} from "../../../../redux/job_contacts";

interface IManageCustomerContact {
  open: boolean;
  data: IJobContact;
  onClose: () => void;
  onComplete: () => void;
}
export const ManageCustomerContact: React.FC<IManageCustomerContact> = (
  props,
) => {
  const { open, data, onClose, onComplete } = props;
  const { user_uuid } = useLoggedInUserInfo();
  const isUpdate = data?.contact_uuid ? true : false;
  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
  } = useFormik({
    initialValues: data,
    validate: (values) => {
      const errors: any = {};
      if (!values.name) {
        errors.name = error_message.required;
      }
      if (values.contact_number && values.contact_number.length !== 10) {
        errors.contact_number = error_message.phone;
      }
      if (!values.mail_id) {
        errors.mail_id = error_message.required;
      } else if (
        values.mail_id !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.mail_id,
        )
      ) {
        errors.mail_id = error_message.invalid_email;
      }
      return errors;
    },
    onSubmit: async (values) => {
      dispatch(
        upsertContactsAsync(
          {
            ...values,
            created_by_uuid: user_uuid,
            customer_name: values.customer_name,
            customer_uuid: values.customer_uuid,
          },
          (isSuccess) => {
            if (isSuccess) {
              onComplete();
            }
          },
        ),
      );
    },
  });

  const handleLocation = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      executive_location_line2: data.address,
      executive_location_city: data.city,
      executive_location_state: data.state,
      executive_location_country: data.country,
      executive_location_pincode: data.postalCode,
    });
  };
  return (
    <RightPanel
      open={open}
      heading={isUpdate ? "Update Contact" : "Create Contact"}
      subHeading={`Customer: ${values.customer_name}`}
      width="50%"
      isWrappedWithForm
      onFormSubmit={handleSubmit}
      actionButtons={() => (
        <Stack direction={"row"} spacing={2}>
          <Button variant="contained" type="button" onClick={onClose}>
            Close
          </Button>
          <Button variant="contained" type="submit">
            Save
          </Button>
        </Stack>
      )}
    >
      {/* <Breadcrumb title="Lead" items={BCrumb} /> */}
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Name"}
              type="text"
              id="name"
              fullWidth
              value={values.name}
              error={errors.name ? true : false}
              helperText={errors.name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Contact Number</CustomFormLabel>
            <PhoneTextField
              name="contact_number"
              variant="outlined"
              size="small"
              fullWidth
              value={values.contact_number}
              error={errors.contact_number ? true : false}
              helperText={errors.contact_number}
              setFieldValue={setFieldValue}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Email"}
              type="text"
              id="mail_id"
              fullWidth
              value={values.mail_id}
              error={errors.mail_id ? true : false}
              helperText={errors.mail_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Landline"}
              type="text"
              id="company_landline"
              fullWidth
              value={values.company_landline}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Department"}
              type="text"
              id="department"
              fullWidth
              value={values.department}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"DOB"}
              type="text"
              id="dob"
              fullWidth
              value={values.dob}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Extension"}
              type="text"
              id="extension"
              fullWidth
              value={values.extension}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Fax"}
              type="text"
              id="fax"
              fullWidth
              value={values.fax}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Previous Organization"}
              type="text"
              id="previous_organisation"
              fullWidth
              value={values.previous_organisation}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Salutation"}
              type="text"
              id="salutation"
              fullWidth
              value={values.salutation}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Skype Id"}
              type="text"
              id="skype_id"
              fullWidth
              value={values.skype_id}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Website"}
              type="text"
              id="website"
              fullWidth
              value={values.website}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Designation"}
              type="text"
              id="designation"
              fullWidth
              value={values.designation}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Contact Type</CustomFormLabel>
            <ControlledCustomSelect
              name="contact_type"
              fullWidth
              value={values.contact_type}
              options={contact_type}
              onChange={handleChange}
              displayEmpty
              placeholder="Select one"
            />
          </Grid>
          {/* <Grid item xs={12} lg={3}>
                        <CustomerAutoSearch
                            label="Customer Name"
                            value={{
                                customer_uuid: values.customer_uuid,
                                customer_name: values.customer_name,
                            }}
                            onSelect={(value) => {
                                setValues({
                                    ...values,
                                    customer_uuid: value.customer_uuid as string,
                                    customer_name: value.customer_name,
                                });
                            }}
                            disabled
                        />
                    </Grid> */}
          <Grid item xs={12} lg={6}>
            <CustomFormLabel>Location</CustomFormLabel>
            <LocationAutoComplete
              value={values.executive_location_line2}
              onLocationChange={handleLocation}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 1"}
              type="text"
              id="executive_location_line1"
              fullWidth
              value={values.executive_location_line1}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Address line 2"}
              type="text"
              id="executive_location_line2"
              fullWidth
              value={values.executive_location_line2}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"City"}
              type="text"
              id="executive_location_city"
              fullWidth
              value={values.executive_location_city}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"State"}
              type="text"
              id="executive_location_state"
              fullWidth
              value={values.executive_location_state}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Pin Code"}
              type="text"
              id="executive_location_pincode"
              fullWidth
              value={values.executive_location_pincode}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <TextFieldWithLabel
              heading={"Country"}
              type="text"
              id="executive_location_country"
              fullWidth
              value={values.executive_location_country}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} lg={3}>
            <CustomFormLabel>Status</CustomFormLabel>
            <ControlledCustomSelect
              name="status"
              fullWidth
              value={values.status}
              onChange={handleChange}
              options={[
                { label: "Active", value: "ACTIVE" },
                { label: "Inactive", value: "INACTIVE" },
              ]}
              displayEmpty
              placeholder="Select one"
            />
          </Grid>
        </Grid>
      </form>
    </RightPanel>
  );
};
