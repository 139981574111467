import React, { useEffect, useState } from "react";
import { useDispatchWrapper } from "../../../hooks";
import { useFormik } from "formik";
import { Dialog } from "../../../components/Dialogs/Dialog";
import { Grid } from "@mui/material";
import {
    CustomFormLabel,
    CustomTextField,
} from "../../../components/formsComponents";
import { useNavigate } from "react-router-dom";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import { clearUserZone, upsertUserZoneAysnc } from "../../../redux/DataManagement/dataManagementActions";
import { IManageZoneProps } from "./ManageZone.types";
import RockworthBranchAutoSearch from "../../../components/AutoCompleteSearches/RockworthBranchAutoSearch";
import { IBranch } from "../../../redux/DataManagement/dataManagement.types";


export const ManageZoneDialog: React.FC<IManageZoneProps> = (props) => {
    const { open, onClose, zoneData, onComplete } = props;
    const [saveLoading, setSaveLoading] = React.useState(false);
    const [isUpdated, setIsUpdated] = useState(false)
    const dispatch = useDispatchWrapper();
    const navigate = useNavigate();
    const {
        values,
        errors,
        setValues,
        handleChange,
        handleSubmit,
        setFieldValue,
    } = useFormik({
        initialValues: zoneData,
        validate: (values) => {
            const errors: any = {};

            return errors;
        },
        onSubmit: async (values) => {
            setSaveLoading(true);
            dispatch(
                upsertUserZoneAysnc({ ...values, zone_uuid: values.zone_uuid }, (isSuccess) => {
                    if (isSuccess) {
                        onClose()
                        navigate(`/zone-list`);
                        onComplete()
                    }
                    setSaveLoading(false);
                }),
            );
        },
    });

    useEffect(() => {
        if (values.zone_uuid) {
            setIsUpdated(true);
        }
    }, [values.zone_uuid]);

    useEffect(() => {
        return () => {
            dispatch(clearUserZone())
        }
    }, [])
    return (
        <Dialog
            open={open}
            title={isUpdated ? "Update Zone" : "Create New Zone"}
            onClose={onClose}
            size="md"
            contentWrappedWithForm={{ onSubmit: handleSubmit }}
            actions={[
                {
                    type: "button",
                    label: "Close",
                    variant: "text",
                    onClick: onClose
                },
                {
                    type: "submit",
                    label: "Save",
                    variant: "contained",
                    disabled: saveLoading,
                },
            ]}
        >
            <Grid container spacing={1}>
                <LoadingDialog open={saveLoading} />
                <Grid item xs={12} lg={12}>
                    <CustomFormLabel>Zone Name</CustomFormLabel>
                    <CustomTextField
                        name="zone_name"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        value={values.zone_name}
                        // error={errors.first_name ? true : false}
                        onChange={handleChange}
                    />
                </Grid>
                <Grid item xs={12} lg={12}>
                    <RockworthBranchAutoSearch
                        label="Select Branch"
                        value={zoneData.branches_uuid}
                        onSelect={(branches: IBranch[]) => {
                            setFieldValue("branches_uuid", branches.map(branch => ({
                                branch_name: branch.branch_name,
                                branch_uuid: branch.branch_uuid
                            })));
                        }}
                    />
                </Grid>
            </Grid>
        </Dialog>
    );
};
