import { action } from "typesafe-actions";
import { ThunkAction } from "redux-thunk";
import { IStoreState } from "../initialStoreState";
import { AnyAction } from "redux";
import { api } from "../../api/api";
import { showMessage } from "../messages/messagesActions";
import { IDirectOpportunity } from "./opportunites.types";
import { IDate } from "../../components/Table/hooks/useDateFilter";

export const FETCH_OPPORTUNITES_LIST_PROGRESS =
  "FETCH_OPPORTUNITES_LIST_PROGRESS";
export const FETCH_OPPORTUNITES_LIST_SUCCESS =
  "FETCH_OPPORTUNITES_LIST_SUCCESS";
export const FETCH_OPPORTUNITES_LIST_FAILED = "FETCH_OPPORTUNITES_LIST_FAILED";

export const fetchOpportunitesListProgress = () =>
  action(FETCH_OPPORTUNITES_LIST_PROGRESS);
export const fetchOpportunitesListSuccess = (
  data: IDirectOpportunity[],
  totalRecords: number,
) =>
  action(FETCH_OPPORTUNITES_LIST_SUCCESS, {
    data,
    totalRecords,
  });
export const fetchOpportunitesListFailed = () =>
  action(FETCH_OPPORTUNITES_LIST_FAILED);

export const fetchOpportunitesListAsync =
  (
    pageNumber: number,
    rowsInPerPage: number,
    status: string,
    date: IDate,
    searchValue: string,
    searchType: string[],
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchOpportunitesListProgress());
      let url = "";
      if (status !== "-1") {
        url = "&status=" + status;
      }
      let finalUrl = `/lead/get-opportunity?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${date.fromDate}&to_date=${date.toDate}${url}`;
      if (searchType.length > 0 && searchValue) {
        finalUrl = `/lead/get-opportunity?pageNo=${pageNumber}&itemPerPage=${rowsInPerPage}&from_date=${
          date.fromDate
        }&to_date=${date.toDate}${url}&column=${[
          searchType,
        ]}&value=${searchValue}`;
      }
      const res = await api.get(finalUrl);
      const data: IDirectOpportunity[] = res.data.data;
      const totalRecords = res.data.totalRecords;

      dispatch(fetchOpportunitesListSuccess(data, totalRecords));
    } catch (err: any) {
      dispatch(fetchOpportunitesListFailed());
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const FETCH_OPPORTUNITES_PROGRESS = "FETCH_OPPORTUNITES_PROGRESS";
export const FETCH_OPPORTUNITES_SUCCESS = "FETCH_OPPORTUNITES_SUCCESS";
export const FETCH_OPPORTUNITES_FAILED = "FETCH_OPPORTUNITES_FAILED";

export const fetchOpportunitesProgress = () =>
  action(FETCH_OPPORTUNITES_PROGRESS);
export const fetchOpportunitesSuccess = (data: IDirectOpportunity) =>
  action(FETCH_OPPORTUNITES_SUCCESS, { data });
export const fetchOpportunitesFailed = (errorMessage: string) =>
  action(FETCH_OPPORTUNITES_FAILED, { errorMessage });

export const fetchOpportunitesAysnc =
  (oppId: number): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      dispatch(fetchOpportunitesProgress());
      const res = await api.get(`/lead/get-opportunity?id=${oppId}`);
      const data: IDirectOpportunity[] = res.data.data;
      if (data.length > 0) {
        dispatch(fetchOpportunitesSuccess(data[0]));
      } else {
        dispatch(
          fetchOpportunitesFailed(
            "Oops! We couldn't find any records associated with your opportunites number at the moment. Please ensure that the provided information is accurate and try again.",
          ),
        );
      }
    } catch (err: any) {
      dispatch(fetchOpportunitesFailed(""));
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const upsertOpportunitesAysnc =
  (
    opportunites: IDirectOpportunity,
    onCallback: (isSuccess: boolean, taskId?: number) => void,
  ): ThunkAction<void, IStoreState, {}, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const res = await api.post("/lead/create-opportunity", opportunites);
      dispatch(
        showMessage({
          type: "success",
          message: "Opportunity is saved succesfully!",
          displayAs: "snackbar",
        }),
      );
      dispatch(fetchOpportunitesSuccess(opportunites));
      onCallback(true, res.data.data.task_id);
    } catch (err: any) {
      onCallback(false);
      dispatch(
        showMessage({
          type: "error",
          message: err.response.data.message,
          displayAs: "snackbar",
        }),
      );
    }
  };

export const CLEAR_OPPORTUNITES_STATE = "CLEAR_OPPORTUNITES_STATE";
export const CLEAR_OPPORTUNITES = "CLEAR_OPPORTUNITES";

export const clearOpportunityState = () => action(CLEAR_OPPORTUNITES_STATE);
export const clearOpportunity = () => action(CLEAR_OPPORTUNITES);
