import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { rootReducer } from "./RootReducer";
import { initialStoreState } from "./initialStoreState";

//@ts-ignore
export const store = createStore(
  rootReducer,
  initialStoreState,
  composeWithDevTools(applyMiddleware(thunk)),
);
