import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import { QuoteActions } from ".";
import {
  CLEAR_QUOTE,
  CLEAR_QUOTE_STATE,
  FETCH_QUOTE_FAILED,
  FETCH_QUOTE_LIST_FAILED,
  FETCH_QUOTE_LIST_PROGRESS,
  FETCH_QUOTE_LIST_SUCCESS,
  FETCH_QUOTE_PROGRESS,
  FETCH_QUOTE_SUCCESS,
} from "./quoteActions";
import { defaultProformaInvoice, defaultQuoteState } from "./defaultState";
import {
  CLEAR_PI_LIST_BY_ENQUIRY,
  CLEAR_QUOTE_PROFORMA_INVOICE,
  CLEAR_QUOTE_PROFORMA_INVOICE_LIST,
  FETCH_PI_LIST_BY_ENQUIRY_FAILED,
  FETCH_PI_LIST_BY_ENQUIRY_PROGRESS,
  FETCH_PI_LIST_BY_ENQUIRY_SUCCESS,
  FETCH_QUOTE_PROFORMA_INVOICE_FAILED,
  FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED,
  FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS,
  FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS,
  FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS,
  FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS,
} from "./proformaInvoiceActions";

export const quotesReducer = (
  state: IStoreState["quotes"] = defaultQuoteState,
  action: QuoteActions,
) => {
  switch (action.type) {
    case FETCH_QUOTE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_QUOTE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = list;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_QUOTE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_QUOTE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.quoteLoading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_QUOTE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.quoteLoading = LoadState.Loaded;
        draftState.quote = data;
      });
      return newState;
    }
    case FETCH_QUOTE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.quoteLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_QUOTE: {
      const newState = produce(state, (draftState) => {
        draftState.quoteLoading = LoadState.NotLoaded;
        draftState.quote = defaultQuoteState["quote"];
      });
      return newState;
    }
    // ************************** Proforma Invoices ***********************************
    case FETCH_QUOTE_PROFORMA_INVOICE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.allPIList.loading = LoadState.InProgress;
        draftState.allPIList.data = [];
        draftState.allPIList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_QUOTE_PROFORMA_INVOICE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.allPIList.loading = LoadState.Loaded;
        draftState.allPIList.data = list;
        draftState.allPIList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_QUOTE_PROFORMA_INVOICE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.allPIList.loading = LoadState.InProgress;
        draftState.allPIList.data = [];
        draftState.allPIList.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_QUOTE_PROFORMA_INVOICE_LIST: {
      const newState = produce(state, (draftState) => {
        draftState.allPIList.loading = LoadState.NotLoaded;
        draftState.allPIList.data = [];
        draftState.allPIList.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_QUOTE_PROFORMA_INVOICE_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.quotePI.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_QUOTE_PROFORMA_INVOICE_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.quotePI.loading = LoadState.Loaded;
        draftState.quotePI.data = data;
      });
      return newState;
    }
    case FETCH_QUOTE_PROFORMA_INVOICE_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.quotePI.loading = LoadState.Failed;
        draftState.quotePI.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_QUOTE_PROFORMA_INVOICE: {
      const newState = produce(state, (draftState) => {
        draftState.quotePI.loading = LoadState.NotLoaded;
        draftState.quotePI.data = defaultProformaInvoice;
      });
      return newState;
    }

    // ************************** Proforma Invoices ***********************************
    case FETCH_PI_LIST_BY_ENQUIRY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.quotePIList.loading = LoadState.InProgress;
        draftState.quotePIList.data = [];
        draftState.quotePIList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PI_LIST_BY_ENQUIRY_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.quotePIList.loading = LoadState.Loaded;
        draftState.quotePIList.data = list;
        draftState.quotePIList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PI_LIST_BY_ENQUIRY_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.quotePIList.loading = LoadState.InProgress;
        draftState.quotePIList.data = [];
        draftState.quotePIList.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_PI_LIST_BY_ENQUIRY: {
      const newState = produce(state, (draftState) => {
        draftState.quotePIList.loading = LoadState.NotLoaded;
        draftState.quotePIList.data = [];
        draftState.quotePIList.totalRecords = 0;
      });
      return newState;
    }

    case CLEAR_QUOTE_STATE: {
      return defaultQuoteState;
    }
    default: {
      return state;
    }
  }
};
