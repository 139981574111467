import produce from "immer";
import { IStoreState } from "../initialStoreState";

import { LoadState } from "../../constants/enums";
import { inititalOpportunityState } from "./opportunites.types";
import { OpportunitesActions } from ".";
import {
  CLEAR_OPPORTUNITES,
  CLEAR_OPPORTUNITES_STATE,
  FETCH_OPPORTUNITES_FAILED,
  FETCH_OPPORTUNITES_LIST_FAILED,
  FETCH_OPPORTUNITES_LIST_PROGRESS,
  FETCH_OPPORTUNITES_LIST_SUCCESS,
  FETCH_OPPORTUNITES_PROGRESS,
  FETCH_OPPORTUNITES_SUCCESS,
} from "./opportunitesActions";

export const opportunitesReducer = (
  state: IStoreState["opportunites"] = inititalOpportunityState,
  action: OpportunitesActions,
) => {
  switch (action.type) {
    case FETCH_OPPORTUNITES_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.InProgress;
        draftState.list = [];
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_OPPORTUNITES_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Loaded;
        draftState.list = data;
        draftState.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_OPPORTUNITES_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_OPPORTUNITES_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.oppLoading = LoadState.InProgress;
        draftState.opportinity = inititalOpportunityState["opportinity"];
        draftState.error = null;
      });
      return newState;
    }
    case FETCH_OPPORTUNITES_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.oppLoading = LoadState.Loaded;
        draftState.opportinity = data;
      });
      return newState;
    }
    case FETCH_OPPORTUNITES_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.oppLoading = LoadState.Failed;
        draftState.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_OPPORTUNITES: {
      const newState = produce(state, (draftState) => {
        draftState.oppLoading = LoadState.NotLoaded;
        draftState.opportinity = inititalOpportunityState["opportinity"];
        draftState.error = null;
      });
      return newState;
    }

    case CLEAR_OPPORTUNITES_STATE: {
      return inititalOpportunityState;
    }

    default: {
      return state;
    }
  }
};
