import { Grid } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  RoleAutoSearch,
  RoleIdAutoSearch,
} from "../../../components/AutoCompleteSearches/RoleIdAutoSearch";
import { UserBranchAutoSearch } from "../../../components/AutoCompleteSearches/UserBranchAutoSearch";
import { Dialog } from "../../../components/Dialogs/Dialog";
import LoadingDialog from "../../../components/Dialogs/LoadingDialog";
import {
  ControlledCustomSelect,
  CustomFormLabel,
  CustomTextField,
} from "../../../components/formsComponents";
import { renderDropdownValue } from "../../../helpers";
import { useDispatchWrapper } from "../../../hooks";
import { IUserCreation } from "../../../redux/UserProfileList/userProfile.types";
import {
  createNewUserAsync,
  upsertUserAysnc,
} from "../../../redux/UserProfileList/userProfileActions";
import { IAddNewUserProps } from "./AddNewUser.types";
import { ErrorMessage } from "../../../components/ErrorMessage/ErrorMessage";
import { UsersAutoSearch } from "../../../components/AutoCompleteSearches/UsersAutoSearch";
import { StandardCard } from "../../../components/Cards";
import { ProfileIntroCard } from "../../../components/Profile/ProfileIntoCard";
import { initialUser } from "../../../redux/UserProfileList/userprofileState";
import { CustomDatePicker } from "../../../components/formsComponents/CustomDatePicker";
import moment from "moment";
import { PhoneTextField } from "../../../components/PhoneTextField/PhoneTextField";
import { DepartmentAutoSearch } from "../../../components/AutoCompleteSearches/DepartmentAutoSearch";
import {
  ILocationResponsePayload,
  LocationAutoComplete,
} from "../../../components/LocationAutoComplete/LocationAutoComplete";

const INITIAL_STATE: IUserCreation = {
  first_name: "",
  last_name: "",
  email: "",
  user_password: "",
  confirmPassword: "",
  branch_uuid: "",
  branch_name: "",
  manager_uuid: "",
  manager_name: "",
  status: "ACTIVE",
  role_uuid: "",
  role_value: "",
};

export const AddNewUserForm: React.FC<IAddNewUserProps> = (props) => {
  const { open, onClose } = props;

  const [saveLoading, setSaveLoading] = React.useState(false);

  const dispatch = useDispatchWrapper();
  const navigate = useNavigate();
  const {
    values,
    errors,
    setValues,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      ...initialUser,
      user_password: "",
      confirmPassword: "",
      role_value: "",
      manager_uuid: "",
      manager_name: "",
    },
    validate: (values) => {
      const errors: any = {};
      if (!values.first_name) {
        errors.first_name = "First name is required.";
      }
      if (!values.personal_email) {
        errors.personal_email = "Email is required.";
      }
      if (
        values.personal_email !== "" &&
        !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
          values.personal_email,
        )
      ) {
        errors.personal_email = "*Invalid email address.";
      }
      if (!values.manager_uuid) {
        errors.manager_uuid = "Password is required.";
      }
      if (!values.user_password) {
        errors.user_password = "Manager is required.";
      }
      if (values.user_password.length < 6) {
        errors.user_password = "Password must be at least 6 characters long.";
      }
      if (!values.confirmPassword) {
        errors.confirmPassword = "Confirm password is required.";
      }
      if (values.user_password !== values.confirmPassword) {
        errors.confirmPassword = "Passwords are not matching.";
      }
      if (!values.role_uuid) {
        errors.role_uuid = "Role is required.";
      }
      if (!values.branch_uuid) {
        errors.branch_uuid = "Role is required.";
      }
      return errors;
    },
    onSubmit: async (values) => {
      setSaveLoading(true);
      dispatch(
        values.user_uuid
          ? upsertUserAysnc(values, (isSuccess) => {
              if (isSuccess) {
                onClose();
              }
              setSaveLoading(false);
            })
          : createNewUserAsync(
              {
                first_name: values.first_name,
                last_name: values.last_name || "",
                email: values.personal_email,
                user_password: values.user_password,
                confirmPassword: values.confirmPassword,
                branch_uuid: values.branch_uuid,
                branch_name: values.branch_name,
                manager_uuid: values.manager_uuid,
                manager_name: values.manager_name,
                status: "ACTIVE",
                role_uuid: values.role_uuid,
                role_value: values.role_value,
              },
              (isSuccess, user_data) => {
                if (isSuccess && user_data) {
                  setValues({
                    ...user_data,
                    user_password: values.user_password,
                    confirmPassword: values.confirmPassword,
                    role_value: values.role_value,
                    manager_uuid: values.manager_uuid,
                    manager_name: values.manager_name,
                  });
                }
                setSaveLoading(false);
              },
            ),
      );
    },
  });

  const handleUserCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputText = e.target.value;
    const alphaNumericText = inputText.replace(/[^A-Za-z0-9]/g, "");

    setFieldValue("user_code", alphaNumericText.toUpperCase());
  };

  const handleAddress = (data: ILocationResponsePayload) => {
    setValues({
      ...values,
      street_address: data.address,
      city: data.city,
      country: data.country,
      province_or_state: data.state,
      postal_code: data.postalCode,
    });
  };

  return (
    <Dialog
      open={open}
      title="Create New Employee"
      onClose={onClose}
      size="lg"
      contentWrappedWithForm={{ onSubmit: handleSubmit }}
      actions={[
        { type: "button", label: "Close", variant: "text", onClick: onClose },
        {
          type: "submit",
          label: "Save",
          variant: "contained",
          disabled: saveLoading,
        },
      ]}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <StandardCard
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <ProfileIntroCard />
          </StandardCard>
        </Grid>
        <Grid item xs={12} md={9}>
          <StandardCard heading="Personal Information">
            {/* <LabelCard heading="" /> */}

            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>First Name</CustomFormLabel>
                <CustomTextField
                  name="first_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.first_name}
                  error={errors.first_name ? true : false}
                  helperText={errors.first_name}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>Last Name</CustomFormLabel>
                <CustomTextField
                  name="last_name"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.last_name}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomFormLabel>Date of Birth</CustomFormLabel>
                <CustomDatePicker
                  fullWidth
                  value={values.date_of_birth || ""}
                  onChange={(newValue) => {
                    const newDate = moment(newValue).format("YYYY-MM-DD");
                    setFieldValue("date_of_birth", newDate);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomFormLabel>Mobile</CustomFormLabel>
                <PhoneTextField
                  name="mobile"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={values.mobile}
                  error={errors.mobile ? true : false}
                  helperText={errors.mobile}
                  // setFieldValue={setFieldValue}
                  onChange={(newValue) => {
                    setFieldValue("mobile", newValue);
                  }}
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <CustomFormLabel>Personal Email</CustomFormLabel>
                <CustomTextField
                  name="personal_email"
                  variant="outlined"
                  size="small"
                  type="text"
                  fullWidth
                  value={values.personal_email}
                  error={errors.personal_email ? true : false}
                  helperText={errors.personal_email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>Password </CustomFormLabel>
                <CustomTextField
                  name="user_password"
                  variant="outlined"
                  size="small"
                  type="password"
                  fullWidth
                  value={values.user_password}
                  error={errors.user_password ? true : false}
                  helperText={errors.user_password}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>Confirm Password</CustomFormLabel>
                <CustomTextField
                  name="confirmPassword"
                  variant="outlined"
                  size="small"
                  type="password"
                  fullWidth
                  value={values.confirmPassword}
                  error={errors.confirmPassword ? true : false}
                  helperText={errors.confirmPassword}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <UserBranchAutoSearch
                  label="Branch"
                  error={errors.branch_uuid}
                  value={{
                    branch_uuid: values.branch_uuid,
                    branch_name: values.branch_name,
                  }}
                  onSelect={(value) => {
                    setValues({
                      ...values,
                      branch_uuid: value.branch_uuid as string,
                      branch_name: value.branch_name as string,
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CustomFormLabel>Role Name</CustomFormLabel>
                <RoleAutoSearch
                  value={values.role_uuid}
                  onSelect={(newValue) => {
                    setValues({
                      ...values,
                      role_uuid: newValue.role_uuid as any,
                      role_value: newValue.role_value as string,
                    });
                  }}
                />
                {errors.role_uuid && <ErrorMessage value={errors.role_uuid} />}
              </Grid>
              <Grid item xs={12} md={4}>
                <Grid item xs={12} lg={12}>
                  <UsersAutoSearch
                    label="Manager"
                    value={{
                      first_name: values.manager_name as string,
                      user_uuid: values.manager_uuid as string,
                    }}
                    onSelect={(newValue) => {
                      setValues({
                        ...values,
                        manager_name:
                          newValue.first_name +
                          " " +
                          (newValue.last_name ?? ""),
                        manager_uuid: newValue.user_uuid,
                      });
                    }}
                    disabled={!values.role_value}
                    error={errors.manager_uuid}
                    roleValue={values.role_value}
                    // disabled={(isUpdate && values.project_name) ? true : false}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <DepartmentAutoSearch
                  label="Department"
                  value={{
                    department_name: values.department_name as string,
                    department_uuid: values.department_uuid as string,
                  }}
                  onSelect={(newValue) => {
                    setFieldValue("department_uuid", newValue.department_uuid);
                    setFieldValue("department_name", newValue.department_name);
                  }}
                />
              </Grid>
            </Grid>
          </StandardCard>
        </Grid>
      </Grid>

      {values.user_uuid && (
        <StandardCard heading="Address Details" sx={{ mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CustomFormLabel>Address</CustomFormLabel>
              <LocationAutoComplete
                id="address"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.street_address}
                onLocationChange={handleAddress}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Unit/Suite</CustomFormLabel>
              <CustomTextField
                name="unit_or_suite"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.unit_or_suite}
                onChange={handleChange}
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <CustomFormLabel>City</CustomFormLabel>
              <CustomTextField
                name="city"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.city}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>State/Province</CustomFormLabel>
              <CustomTextField
                name="province_or_state"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.province_or_state}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Postal Code</CustomFormLabel>
              <CustomTextField
                name="postal_code"
                variant="outlined"
                size="small"
                fullWidth
                value={values.postal_code}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={4}>
              <CustomFormLabel>Country</CustomFormLabel>
              <CustomTextField
                name="country"
                variant="outlined"
                size="small"
                type="text"
                fullWidth
                value={values.country}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <CustomFormLabel>Status</CustomFormLabel>
              <ControlledCustomSelect
                variant="outlined"
                size="small"
                type="string"
                name="status"
                fullWidth
                value={values.status}
                onChange={handleChange}
                displayEmpty
                placeholder="Select one"
                renderValue={renderDropdownValue(`Select One`)}
                options={[
                  { label: "Active", value: "ACTIVE" },
                  { label: "Inactive", value: "INACTIVE" },
                ]}
              />
            </Grid>
          </Grid>
        </StandardCard>
      )}
    </Dialog>
  );
};
