import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  BillingCompanyAcions,
  CLEAR_BILLING_COMPANY,
  CLEAR_BILLING_COMPANY_STATE,
  FETCH_BILLING_COMPANY_FAILED,
  FETCH_BILLING_COMPANY_LIST_FAILED,
  FETCH_BILLING_COMPANY_LIST_PROGRESS,
  FETCH_BILLING_COMPANY_LIST_SUCCESS,
  FETCH_BILLING_COMPANY_PROGRESS,
  FETCH_BILLING_COMPANY_SUCCESS,
} from ".";
import {
  defaultBillingCompany,
  defaultBillingCompanyState,
} from "./defaultState";

export const billingCompanyReducer = (
  state: IStoreState["billingCompany"] = defaultBillingCompanyState,
  action: BillingCompanyAcions,
) => {
  switch (action.type) {
    case FETCH_BILLING_COMPANY_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_BILLING_COMPANY_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_BILLING_COMPANY_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
      });
      return newState;
    }

    case FETCH_BILLING_COMPANY_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.billingCompany.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_BILLING_COMPANY_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.billingCompany.loading = LoadState.Loaded;
        draftState.billingCompany.data = data;
      });
      return newState;
    }
    case FETCH_BILLING_COMPANY_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.billingCompany.loading = LoadState.Failed;
        draftState.billingCompany.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_BILLING_COMPANY: {
      const newState = produce(state, (draftState) => {
        draftState.billingCompany.loading = LoadState.NotLoaded;
        draftState.billingCompany.data = defaultBillingCompany;
      });
      return newState;
    }
    case CLEAR_BILLING_COMPANY_STATE: {
      return defaultBillingCompanyState;
    }
    default: {
      return state;
    }
  }
};
