/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, CircularProgress, Stack } from "@mui/material";
import { debounce } from "lodash";
import React from "react";
import { api } from "../../api/api";
import { CustomFormLabel, CustomTextField } from "../formsComponents";

interface ISearchAddressByPincode {
  pincode: string;
  office_name: string;
  district_name: string;
  state_name: string;
  country: string;
}

const INITIAL_STATE = {
  pincode: "",
  office_name: "",
  district_name: "",
  state_name: "",
  country: "India",
};

interface IAutoSearchProps {
  label?: string;
  value: any;
  onSelect: (data: ISearchAddressByPincode) => void;
  disabled?: boolean;
  error?: string;
}

export const AddressAutoCompleteByPincode: React.FC<IAutoSearchProps> = (
  props,
) => {
  const { value, onSelect, label, disabled, error } = props;
  const [options, setOptions] = React.useState<readonly any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [search, setSearchText] = React.useState<any>("");

  const fetchSuggestion = async (value: string) => {
    setLoading(true);
    try {
      const response = await api.get(
        "https://indian-pincode-2024.p.rapidapi.com/",
        {
          params: { pincode: `${value}` },
          headers: {
            "X-RapidAPI-Key": process.env.REACT_APP_PINCODE_API_KEY as string,
            "X-RapidAPI-Host": "indian-pincode-2024.p.rapidapi.com",
          },
        },
      );
      const data = response.data;
      setOptions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const debounceFn = React.useCallback(debounce(fetchSuggestion, 800), []);

  const getOptionLabel = (option: ISearchAddressByPincode | string) => {
    if (typeof option === "string") {
      return option;
    }
    return `${option.office_name} - ${option.district_name} (${option.pincode})`;
  };

  const getValue = () => {
    if (value && typeof value === "object") {
      return options.find((option) => option.pincode === value.pincode) || null;
    }
    return options.find((option) => option.pincode === value) || null;
  };

  const getValueLabel = (value: any | null) => {
    if (value && typeof value === "object") {
      return value.pincode;
    }
    return value || "";
  };

  React.useEffect(() => {
    if (search.length > 5) {
      debounceFn(search);
    }
  }, [search]);

  React.useEffect(() => {
    if (value) {
      const option: any = {
        ...INITIAL_STATE,
        pincode: value,
      };
      setOptions([option]);
    }
  }, [value]);

  return (
    <>
      {label && (
        <Box display="flex" justifyContent={"flex-start"} alignItems="center">
          <CustomFormLabel display={"block"}>{` ${label}  `}</CustomFormLabel>
        </Box>
      )}
      <Stack direction={"row"} justifyContent={"space-between"} spacing={1}>
        <Autocomplete
          id="customer-auto-search"
          fullWidth
          freeSolo
          disabled={disabled}
          sx={{
            ".MuiOutlinedInput-root": {
              paddingTop: "2px",
              paddingBottom: "2px",
              fontSize: "0.8rem",
              color: "rgb(38, 38, 38)",
              width: "100%",
              backgroundColor: disabled ? "#f8f9fb" : "inherit",
            },
          }}
          getOptionLabel={getOptionLabel}
          isOptionEqualToValue={(option, value) =>
            typeof option === "string"
              ? option === value //@ts-ignore
              : option.pincode === value
          }
          filterOptions={(x) => x}
          options={options}
          autoComplete
          includeInputInList
          value={getValueLabel(getValue())}
          loading={loading}
          noOptionsText="No Customers"
          //@ts-ignore
          onChange={(
            event: React.ChangeEvent<{}>,
            newValue: ISearchAddressByPincode | null,
          ) => {
            if (newValue) {
              onSelect(newValue);
            } else {
              onSelect(INITIAL_STATE);
            }
          }}
          onInputChange={(event, newInputValue) => {
            if ((event && event.type === "change") || !newInputValue) {
              setSearchText(newInputValue);
            }
          }}
          renderInput={(params) => (
            <CustomTextField
              {...params}
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading && <CircularProgress color="inherit" size={20} />}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
              disabled={disabled}
              error={error ? true : false}
              helperText={error}
            />
          )}
        />
      </Stack>
    </>
  );
};
