import { lazy } from "react";
import { MODULE_IDS } from "../../constants/enums";
import Loadable from "../../layout/fullLayout/loadable/loadable";
import { AuthorizedRoute } from "../../router/AuthorizedRoute";
import { BILLING_COMPANY_ROUTE } from "./billingCompany.constants";
import { ManageCustomer } from "../customers/ManageCustomer";

const ManageBillingCompanyList = Loadable(
  lazy(() =>
    import("./BillingCompanyList").then(({ BillingCompanyList }) => ({
      default: BillingCompanyList,
    })),
  ),
);

const ManageBillingCompany = Loadable(
  lazy(() =>
    import("./ManageBillingCompany").then(({ ManageBillingCompany }) => ({
      default: ManageBillingCompany,
    })),
  ),
);

export const billingCompanyRoutes = [
  {
    path: BILLING_COMPANY_ROUTE,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BILLING_COMPANY]}>
        <ManageBillingCompanyList />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${BILLING_COMPANY_ROUTE}/manage`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BILLING_COMPANY]}>
        <ManageCustomer isBillingCompany={1} />
      </AuthorizedRoute>
    ),
  },
  {
    path: `${BILLING_COMPANY_ROUTE}/manage/:uuid`,
    exact: true,
    element: (
      <AuthorizedRoute moduleIds={[MODULE_IDS.BILLING_COMPANY]}>
        <ManageCustomer isBillingCompany={1} />
      </AuthorizedRoute>
    ),
  },
];
