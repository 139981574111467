import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_CUSTOMER,
  CLEAR_CUSTOMER_STATE,
  FETCH_CUSTOMER_FAILED,
  FETCH_CUSTOMER_LIST_FAILED,
  FETCH_CUSTOMER_LIST_PROGRESS,
  FETCH_CUSTOMER_LIST_SUCCESS,
  FETCH_CUSTOMER_PROGRESS,
  FETCH_CUSTOMER_SUCCESS,
} from "./customerActions";
import {
  CLEAR_CUSTOMER_BRANCH,
  FETCH_CUSTOMER_BRANCHES_FAILED,
  FETCH_CUSTOMER_BRANCHES_PROGRESS,
  FETCH_CUSTOMER_BRANCHES_SUCCESS,
  FETCH_CUSTOMER_BRANCH_FAILED,
  FETCH_CUSTOMER_BRANCH_LIST_FAILED,
  FETCH_CUSTOMER_BRANCH_LIST_PROGRESS,
  FETCH_CUSTOMER_BRANCH_LIST_SUCCESS,
  FETCH_CUSTOMER_BRANCH_PROGRESS,
  FETCH_CUSTOMER_BRANCH_SUCCESS,
} from "./customerBranchActions";
import { CustomerActions } from ".";
import {
  defaultCustomer,
  defaultCustomerBranch,
  defaultCustomerState,
} from "./defaultState";

export const customerReducer = (
  state: IStoreState["customer"] = defaultCustomerState,
  action: CustomerActions,
) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.InProgress;
        draftState.list.data = [];
        draftState.list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CUSTOMER_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Loaded;
        draftState.list.data = data;
        draftState.list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.list.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_CUSTOMER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customer.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customer.loading = LoadState.Loaded;
        draftState.customer.data = data;
      });
      return newState;
    }
    case FETCH_CUSTOMER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customer.loading = LoadState.Failed;
        draftState.customer.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_CUSTOMER: {
      const newState = produce(state, (draftState) => {
        draftState.customer.loading = LoadState.NotLoaded;
        draftState.customer.data = defaultCustomer;
        draftState.customer.error = null;
      });
      return newState;
    }

    //=============================== Customer Branch  ===============================

    case FETCH_CUSTOMER_BRANCH_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerBranchList.loading = LoadState.InProgress;
        draftState.customerBranchList.data = [];
        draftState.customerBranchList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_LIST_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerBranchList.loading = LoadState.Loaded;
        draftState.customerBranchList.data = data;
        draftState.customerBranchList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.customerBranchList.loading = LoadState.Failed;
      });
      return newState;
    }

    case FETCH_CUSTOMER_BRANCH_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerBranch.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerBranch.loading = LoadState.Loaded;
        draftState.customerBranch.data = data;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCH_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerBranch.loading = LoadState.Failed;
        draftState.customerBranch.error = errorMessage;
      });
      return newState;
    }

    case CLEAR_CUSTOMER_BRANCH: {
      const newState = produce(state, (draftState) => {
        draftState.customerBranch.loading = LoadState.NotLoaded;
        draftState.customerBranch.data = defaultCustomerBranch;
        draftState.customerBranch.error = null;
      });
      return newState;
    }


    case CLEAR_CUSTOMER_STATE: {
      return defaultCustomerState;
    }
    case FETCH_CUSTOMER_BRANCHES_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.customerBranchList.loading = LoadState.InProgress;
        draftState.customerBranchList.data = [];
        draftState.customerBranchList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCHES_SUCCESS: {
      const { data, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.customerBranchList.loading = LoadState.Loaded;
        draftState.customerBranchList.data = data;
        draftState.customerBranchList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_CUSTOMER_BRANCHES_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.customerBranchList.loading = LoadState.Failed;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
