import produce from "immer";
import { IStoreState } from "../initialStoreState";
import { LoadState } from "../../constants/enums";
import {
  CLEAR_PURCHASE_ORDER_STATE,
  CLEAR_SINGLE_PURCHASE_ORDER,
  defaultPurchaseOrderState,
  FETCH_PURCHASE_ORDER_LIST_FAILED,
  FETCH_PURCHASE_ORDER_LIST_PROGRESS,
  FETCH_PURCHASE_ORDER_LIST_SUCCESS,
  FETCH_SINGLE_PURCHASE_ORDER_FAILED,
  FETCH_SINGLE_PURCHASE_ORDER_PROGRESS,
  FETCH_SINGLE_PURCHASE_ORDER_SUCCESS,
  IPurchaseOrderActions,
} from ".";

export const purchaseOrderReducer = (
  state: IStoreState["purchase_order"] = defaultPurchaseOrderState,
  action: IPurchaseOrderActions,
) => {
  switch (action.type) {
    case FETCH_PURCHASE_ORDER_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.purchase_order_list.loading = LoadState.InProgress;
        draftState.purchase_order_list.data = [];
        draftState.purchase_order_list.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_PURCHASE_ORDER_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.purchase_order_list.loading = LoadState.Loaded;
        draftState.purchase_order_list.data = list;
        draftState.purchase_order_list.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_PURCHASE_ORDER_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.purchase_order_list.loading = LoadState.InProgress;
        draftState.purchase_order_list.data = [];
        draftState.purchase_order_list.totalRecords = 0;
      });
      return newState;
    }

    case FETCH_SINGLE_PURCHASE_ORDER_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_SINGLE_PURCHASE_ORDER_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.Loaded;
        draftState.single_purchase_order.data = data;
      });
      return newState;
    }
    case FETCH_SINGLE_PURCHASE_ORDER_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.Failed;
        draftState.single_purchase_order.error = errorMessage;
      });
      return newState;
    }
    case CLEAR_SINGLE_PURCHASE_ORDER: {
      const newState = produce(state, (draftState) => {
        draftState.single_purchase_order.loading = LoadState.NotLoaded;
        draftState.single_purchase_order.data =
          defaultPurchaseOrderState["single_purchase_order"].data;
        draftState.single_purchase_order.error = null;
      });
      return newState;
    }
    case CLEAR_PURCHASE_ORDER_STATE: {
      return defaultPurchaseOrderState;
    }

    // case FETCH_PURCHASE_ORDER_ANALYTICS_LIST_PROGRESS: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.analytics.loading = LoadState.InProgress;
    //     draftState.analytics.data = [];
    //   });
    //   return newState;
    // }
    // case FETCH_PURCHASE_ORDER_ANALYTICS_LIST_SUCCESS: {
    //   const { data } = action.payload;
    //   const newState = produce(state, (draftState) => {
    //     draftState.analytics.loading = LoadState.Loaded;
    //     draftState.analytics.data = data;
    //   });
    //   return newState;
    // }
    // case FETCH_PURCHASE_ORDER_ANALYTICS_LIST_FAILED: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.analytics.loading = LoadState.InProgress;
    //     draftState.analytics.data = [];
    //   });
    //   return newState;
    // }

    // case CLEAR_PURCHASE_ORDER_ANALYTICS: {
    //   const newState = produce(state, (draftState) => {
    //     draftState.analytics.loading = LoadState.NotLoaded;
    //     draftState.analytics.data = defaultPurchaseOrderState["analytics"].data;
    //   });
    //   return newState;
    // }

    default: {
      return state;
    }
  }
};
