import produce from "immer";
import { DataManagementActions } from ".";
import { LoadState } from "../../constants/enums";
import { IStoreState } from "../initialStoreState";
import {
  FETCH_USER_BRANCH_LIST_PROGRESS,
  FETCH_USER_BRANCH_LIST_SUCCESS,
  FETCH_USER_BRANCH_LIST_FAILED,
  CLEAR_USER_BRANCH,
  FETCH_USER_ZONE_LIST_PROGRESS,
  FETCH_USER_ZONE_LIST_SUCCESS,
  FETCH_USER_ZONE_LIST_FAILED,
  CLEAR_USER_BRANCH_STATE,
  FETCH_USER_BRANCH_PROGRESS,
  FETCH_USER_BRANCH_SUCCESS,
  FETCH_USER_BRANCH_FAILED,
  CLEAR_USER_ZONE,
  CLEAR_USER_ZONE_STATE,
} from "./dataManagementActions";
import { defaultDataManagementState, defaultUserBranch, defaultUserZone } from "./defaultState";

export const dataManagementReducer = (
  state: IStoreState["dataManagement"] = defaultDataManagementState,
  action: DataManagementActions,
) => {
  switch (action.type) {
    case FETCH_USER_BRANCH_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.branchList.loading = LoadState.InProgress;
        draftState.branchList.list = [];
        draftState.branchList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_USER_BRANCH_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.branchList.loading = LoadState.Loaded;
        draftState.branchList.list = list;
        draftState.branchList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_USER_BRANCH_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.branchList.loading = LoadState.Failed;
      });
      return newState;
    }
    case FETCH_USER_BRANCH_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.branch.loading = LoadState.InProgress;
      });
      return newState;
    }
    case FETCH_USER_BRANCH_SUCCESS: {
      const { data } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.branch.loading = LoadState.Loaded;
        draftState.branch.data = data;
      });
      return newState;
    }
    case FETCH_USER_BRANCH_FAILED: {
      const { errorMessage } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.branch.loading = LoadState.Failed;
        draftState.branch.error = errorMessage;
      });
      return newState;
    }

    case FETCH_USER_ZONE_LIST_PROGRESS: {
      const newState = produce(state, (draftState) => {
        draftState.zoneList.loading = LoadState.InProgress;
        draftState.zoneList.list = [];
        draftState.zoneList.totalRecords = 0;
      });
      return newState;
    }
    case FETCH_USER_ZONE_LIST_SUCCESS: {
      const { list, totalRecords } = action.payload;
      const newState = produce(state, (draftState) => {
        draftState.zoneList.loading = LoadState.Loaded;
        draftState.zoneList.list = list;
        draftState.zoneList.totalRecords = totalRecords;
      });
      return newState;
    }
    case FETCH_USER_ZONE_LIST_FAILED: {
      const newState = produce(state, (draftState) => {
        draftState.zoneList.loading = LoadState.Failed;
      });
      return newState;
    }
    case CLEAR_USER_BRANCH_STATE: {
      return defaultDataManagementState;
    }
    case CLEAR_USER_BRANCH: {
      const newState = produce(state, (draftState) => {
        draftState.branch.loading = LoadState.NotLoaded;
        draftState.branch.data = defaultUserBranch;
        draftState.branch.error = null;
      });
      return newState;
    }
    case CLEAR_USER_ZONE_STATE: {
      return defaultDataManagementState;
    }
    case CLEAR_USER_ZONE: {
      const newState = produce(state, (draftState) => {
        draftState.zone.loading = LoadState.NotLoaded;
        draftState.zone.data = defaultUserZone;
        draftState.zone.error = null;
      });
      return newState;
    }
    default: {
      return state;
    }
  }
};
